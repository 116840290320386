export default class PillboxConfig {
    getContentType = function (page) {
        let sections = ['Movies', 'Audio', 'TV Series', 'Games', 'Live TV', 'Electronic Reader'];
        let searched = sections.filter((item) => item === page);

        return searched.length > 0 ? searched : null;
    };

    getPillBoxSections = function (usageSource) {
        let sections = ['flights', 'tails', 'destination_countries', 'destination_airports', 'routes'];

        switch (usageSource) {
            case 'Companion App':
                sections = sections.concat(['devices', 'login_attempts']);
                break;
            case 'Bluetooth':
                sections = sections.concat([
                    'paired_devices',
                    'total_connections',
                    'total_disconnections',
                    'pairing_attempts',
                    'bt_users',
                    'avg_connections',
                    'avg_disconnections',
                    'avg_connection_time'
                ]);
                break;
            case 'Portal':
                sections = sections.concat([
                    'total_sessions',
                    'total_page_views',
                    'avg_session_duration',
                    'avg_page_view_duration',
                    'devices'
                ]);
                break;
            case 'Portal Navigation':
                sections = sections.concat([
                    'total_sessions',
                    'total_page_views',
                    'avg_session_duration',
                    'avg_page_view_duration',
                    'devices'
                ]);
                break;
            case 'Seatback':
                sections = sections.concat([
                    'total_sessions',
                    'total_page_views',
                    'avg_session_duration',
                    'avg_page_view_duration',
                    'login_attempts'
                ]);
                break;
            case 'Electronic Reader':
                sections = sections.concat([
                    'total_readers',
                    'total_reading_time',
                    'total_page_reads',
                    'total_title_views',
                    'unique_title_views',
                    'avg_reading_time',
                    'avg_page_reads',
                    'avg_title_views'
                ]);
                break;
            case 'Live TV':
                sections = sections.concat([
                    'total_channel_hits',
                    'total_viewing_time',
                    'total_flight_duration',
                    'total_unique_passengers',
                    'avg_passengers_per_flight',
                    'avg_time_per_flight',
                    'avg_time_per_passenger',
                    'avg_content_viewing_time'
                ]);
                break;
            case 'Marketplace':
                sections = ['flights', 'marketplace_sessions', 'marketplace_devices'];
            default:
                // use default sections
                sections = sections.concat([]);
        }

        return sections;
    };
}
