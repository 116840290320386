import InsightsMonthlyService from 'services/InsightsMonthlyService';
import * as AppConstants from 'constants/app/constants';
import DashboardHelper from 'components/widgets/dashboards/DashboardHelper';

const dashboardHelper = new DashboardHelper();

class SubscriptionService {
    static data = '';

    static refreshToken = '';

    static stage = AppConstants.SUBSCRIPTION_STAGE_0;

    static subscription() {
        return window.sessionStorage.getItem('pac_subscription');
    }

    static async fetchSubscription(refetch = false) {
        if (sessionStorage.getItem('rack_data_env') == 'Rack' || sessionStorage.getItem('rack_data_env') == 'All') {
            this.data = dashboardHelper.getStubbedSubscription('pac');
            this.data = this.setSubscription(this.data);
        } else if (this.data === '' || refetch) {
            let service = new InsightsMonthlyService();
            this.data = await service.fetchSubscriptionData();

            // Temporary fix to enable My Insights. Remove when DP repo change is pushed to prod.
            if (this.data.tiers && !this.data.tiers[0].apps.includes('My Insights')) {
                this.data.tiers[0].apps.push('My Insights');
            }

            this.data = this.setSubscription(this.data);
        }
        return this.data;
    }

    static setSubscription(response) {
        if ((response && response.tiers) || (response && window.sessionStorage.getItem('rack_data_env') == 'All')) {
            let currTenantIcaoCode = sessionStorage.getItem('pac_selected_tenant_icao_code');
            if (
                currTenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS ||
                (response && window.sessionStorage.getItem('rack_data_env') == 'All')
            ) {
                response = dashboardHelper.getStubbedSubscription(currTenantIcaoCode);
            }

            let subscription = '';
            if (Array.isArray(response.tiers) && Object.keys(response.tiers).length > 0) {
                let defaultTier = response.tiers[0];
                for (let k in response.tiers) {
                    if (response.tiers[k]['subscription-level'] === 'Standard') {
                        defaultTier = response.tiers[k];
                        break;
                    }
                }

                subscription = defaultTier['subscription-level'];
                this.stage = defaultTier['stage'];
            } else {
                subscription = 'No Subscription';
            }

            window.sessionStorage.setItem('pac_subscription', subscription);
        }

        return response;
    }

    static setStage(stage) {
        this.stage = stage;
    }
}

export default SubscriptionService;
