import BaseService from 'services/BaseService';
import LinkHelper from 'util/LinkHelper';

const linkhelper = new LinkHelper();

class ReleaseNotificationService extends BaseService {
    viewStatus = null;

    static releaseVersion() {
        //sidebar value
        return '5.3.0';
    }

    static softwareReleaseVersion() {
        return '5.3';
    }

    getViewCount() {
        return this.viewStatus['data']['release_notification_count'];
    }

    async popupDisplayed() {
        let releaseNotificationSessionId = window.localStorage.getItem('release_notification_session_id');

        let sessionId = window.localStorage.getItem('sessionId');
        while (sessionId === null) {
            await new Promise((r) => setTimeout(r, 1000));
            sessionId = window.localStorage.getItem('sessionId');
        }

        if (releaseNotificationSessionId === sessionId) {
            return true;
        } else {
            window.localStorage.setItem('release_notification_session_id', sessionId);
            return false;
        }
    }

    async fetchUserReleaseNotificationViewStatus(tier) {
        let path = process.env.REACT_APP_INSIGHTS_API_URL + `/v2/info/get_release_notification_status`;

        let headers;
        try {
            headers = {
                'pac-selected-tenant-icao': linkhelper.getCurAuthTenantIcao(),
                Authorization: await this.getAuthorizationHeader()
            };
        } catch (error) {
            console.log(error);
            return;
        }

        let queryStringParameters = Object.assign(
            {},
            { release_id: ReleaseNotificationService.releaseVersion(), tier: tier }
        );

        let options = {
            body: {},
            headers: headers,
            params: queryStringParameters
        };

        return new Promise((resolve, reject) => {
            this.makeGetApiCallAxios(path, options).then((response) => {
                if (response) {
                    this.viewStatus = response;
                    resolve(response);
                } else {
                    reject(response);
                }
            });
        });
    }

    async updateUserReleaseNotificationViewStatus(tier) {
        let path = process.env.REACT_APP_INSIGHTS_API_URL + `/v2/info/update_release_notification_login_count`;

        let headers;
        try {
            headers = {
                'pac-selected-tenant-icao': linkhelper.getCurAuthTenantIcao(),
                Authorization: await this.getAuthorizationHeader()
            };
        } catch (error) {
            console.log(error);
            return;
        }

        let body = { release_id: ReleaseNotificationService.releaseVersion(), tier: tier };
        let options = {
            headers: headers
        };

        return new Promise((resolve, reject) => {
            this.makePostApiCallAxios(path, body, options).then((response) => {
                if (response) {
                    return resolve(response);
                } else {
                    return reject(response);
                }
            });
        });
    }
}

export default ReleaseNotificationService;
