import React from 'react';
import SubscriptionBanner from 'components/widgets/SubscriptionBanner';
import PageTabs from 'components/widgets/PageTabs';
import moment from 'moment';
import ErrorBoundary from 'util/ErrorBoundary';
import AirlineSummary from 'components/pillbox/AirlineSummary';
import DynamicFilter from 'components/widgets/DynamicFilter';
import * as AppConstants from 'constants/app/constants';
import { Row, Col } from 'reactstrap';
import DashboardHelper from 'components/widgets/dashboards/DashboardHelper';

const headerTabs = ['Summary', 'Workbench'];
const dashboardHelper = new DashboardHelper();

class PageHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reloadDynamicFilters: true
        };
    }

    displayFilterAndSummaryByPath() {
        if (this.props) {
            if (dashboardHelper.getExcludedPaths().includes(this.props.path)) {
                return false;
            }
        }

        return true;
    }

    isForceReloadDynamicFilters() {
        if (this.state.reloadDynamicFilters) {
            this.setState({ reloadDynamicFilters: false });
            return true;
        }

        return false;
    }

    render() {
        let showFilterAndSummary = this.displayFilterAndSummaryByPath();
        let refreshDynamicFilters = this.isForceReloadDynamicFilters();

        return (
            <div>
                <Row className="header">
                    {this.props.path === '/app/help' &&
                        this.props.subscriptionStatus !== AppConstants.SUBSCRIPTION_STATUS_ACTIVE && (
                            <SubscriptionBanner
                                subscriptionStatus={this.props.subscriptionStatus}
                                licenseStartDate={this.props.licenseStartDate}
                                licenseEndDate={this.props.licenseEndDate}
                            />
                        )}

                    <Col md="12" sm="12" className="page-title">
                        {this.props.maintitle}
                        {this.props.maintitle === 'My Insights' && (
                            <Col md="12" className="page-date">
                                {moment().format('MMMM DD, YYYY')}
                            </Col>
                        )}
                    </Col>
                </Row>

                {showFilterAndSummary && (
                    <div>
                        <Row>
                            <DynamicFilter
                                ref={this.dynamicFilterRef}
                                forceLoad={refreshDynamicFilters}
                                showWorkbenchLink={this.props.paidTier}
                                onSelectionUpdated={this.props.handleSelectionUpdated}
                                title={this.props.maintitle}
                                app={this.props.appName}
                            />
                        </Row>
                        <Row>
                            <ErrorBoundary>
                                <AirlineSummary
                                    appliedFilters={this.props.appliedFilters}
                                    forceLoad={this.props.refreshAirlineSummary}
                                    onlyComparisonRangeUpdated={this.props.onlyComparisonRangeUpdated}
                                    title={this.props.maintitle}
                                    app={this.props.appName}
                                    allowedApps={this.props.allowedApps}
                                />
                            </ErrorBoundary>
                        </Row>

                        {this.props.showWorkbench &&
                            window.sessionStorage.getItem('rack_data_env') != 'Rack' &&
                            window.sessionStorage.getItem('rack_data_env') != 'All' && (
                                <PageTabs tabs={headerTabs} tabFunction={this.props.changeTab} path={this.props.path} />
                            )}
                    </div>
                )}
            </div>
        );
    }
}

export default PageHeader;
