export default {
    getProductsDropdownListHelper(reportSubType) {
        let dropdownList;

        switch (reportSubType) {
            case 'Marketplace':
                dropdownList = [
                    {
                        label: 'Top 10 Total Views',
                        unit: 'count',
                        value: 'products.most_popular.total_views',
                        text: 'TV Series Total Views',
                        default: 1,
                        free_tier: 1,
                        section: 'top_10_total_views',
                        small_count_label: 'Total Views'
                    },
                    {
                        label: 'Top 10 Total Viewing Time',
                        unit: 'hr',
                        value: 'products.most_popular.total_time',
                        text: 'TV Series Total Viewing Time',
                        free_tier: 1,
                        section: 'top_10_total_time',
                        small_count_label: 'Total Viewing Time'
                    },
                    {
                        label: 'Bottom 10 Total Views',
                        unit: 'count',
                        value: 'products.least_popular.total_views',
                        text: 'TV Series Total Views',
                        free_tier: 1,
                        section: 'bottom_10_total_views',
                        small_count_label: 'Total Views'
                    },
                    {
                        label: 'Bottom 10 Total Viewing Time',
                        unit: 'hr',
                        value: 'products.least_popular.total_time',
                        text: 'TV Series Total Viewing Time',
                        free_tier: 1,
                        section: 'bottom_10_total_time',
                        small_count_label: 'Total Viewing Time'
                    }
                ];
                break;
            case 'Excelmarketplace':
                dropdownList = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'products.all_records.total_views',
                        text: 'Marketplace products Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'products.all_records.total_time',
                        text: 'Marketplace Total Viewing Time',
                        free_tier: 1
                    }
                ];
                break;
            default:
        }

        return dropdownList;
    }
};
