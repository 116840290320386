import { Component } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import FiltersService from 'services/FiltersService';
import Analytics from 'components/widgets/Analytics';
import AuthHelper from 'util/AuthHelper.js';
import TenantsService from 'services/TenantsService';
import * as constants from 'constants/app/constants';

const authHelper = new AuthHelper();

const TENANT_OPTIONS_SESSION_KEY = 'pac_tenants';
const SELECTED_TENANT_ID_SESSION_KEY = 'pac_selected_tenant_id';
const SELECTED_TENANT_ICAO_CODE_SESSION_KEY = 'pac_selected_tenant_icao_code';
const SELECTED_TENANT_SESSION_KEY = 'pac_selected_tenant';
const NONLICENSED_MODE = 'rack_data_env';
const NONLICENSED_TENANTS = 'nonlicensed_tenants';

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faSearch : faCaretDown} />
            </components.DropdownIndicator>
        )
    );
};

export class TenantSwitch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: [],
            selected: null,
            showCustomersDropdown: false,
            isRackTesterRole: false
        };
    }

    componentDidMount() {
        Auth.currentSession()
            .then((currentSession) => {
                let isInternalPACUser = currentSession.idToken.payload.pac_tenant_id == constants.PAC_TENANT_ID;
                if (authHelper.currentUserBelongsToQualityManagementGroup(currentSession) || isInternalPACUser) {
                    this.setState({ showCustomersDropdown: true });
                }

                let options = JSON.parse(window.sessionStorage.getItem(TENANT_OPTIONS_SESSION_KEY));
                if (
                    window.sessionStorage.getItem(NONLICENSED_MODE) == 'All' ||
                    window.sessionStorage.getItem(NONLICENSED_MODE) == 'Rack'
                ) {
                    options = JSON.parse(window.sessionStorage.getItem(NONLICENSED_TENANTS));
                }
                let selectedTenant = JSON.parse(window.sessionStorage.getItem(SELECTED_TENANT_SESSION_KEY));
                selectedTenant = options
                    .filter((option) => {
                        return option.icaoCode.toString() === selectedTenant.icaoCode.toString();
                    })
                    .shift();
                if (!selectedTenant) {
                    if (
                        window.sessionStorage.getItem(NONLICENSED_MODE) == 'All' ||
                        window.sessionStorage.getItem(NONLICENSED_MODE) == 'Rack'
                    ) {
                        selectedTenant = options[0];
                    } else {
                        selectedTenant = {
                            label: 'Panasky',
                            value: '903a42bc-985e-40d1-9c72-32b8c2e33397',
                            icaoCode: 'pac'
                        };
                    }
                }

                window.sessionStorage.setItem(SELECTED_TENANT_ID_SESSION_KEY, selectedTenant.value);
                window.sessionStorage.setItem(SELECTED_TENANT_SESSION_KEY, JSON.stringify(selectedTenant));
                window.sessionStorage.setItem(SELECTED_TENANT_ICAO_CODE_SESSION_KEY, selectedTenant.icaoCode);
                this.setState({
                    options: options,
                    selected: selectedTenant
                });

                this.props.onTenantSelection(window.sessionStorage.getItem(SELECTED_TENANT_ICAO_CODE_SESSION_KEY));
            })
            .catch((err) => {
                console.debug('tenant_switch err - ' + err);
                console.error(err);
            });
    }

    handleTenantChange = async (customerId) => {
        let { history } = this.props;

        try {
            let selectedTenant = this.getCustomerById(customerId.value);

            window.sessionStorage.removeItem('pac_next_insights_selected_filters');
            window.sessionStorage.removeItem('pac_next_insights_tail_selected_filters');

            window.sessionStorage.setItem(SELECTED_TENANT_SESSION_KEY, JSON.stringify(selectedTenant));
            window.sessionStorage.setItem(SELECTED_TENANT_ID_SESSION_KEY, selectedTenant.value);
            window.sessionStorage.setItem(SELECTED_TENANT_ICAO_CODE_SESSION_KEY, selectedTenant.icaoCode);

            await Analytics.analytics.track('handleTenantChange', {
                visual_name: 'Tenants',
                interaction_type: 'Tenant Switch',
                interaction_value: selectedTenant.label
            });

            Analytics.analytics.plugins['insights'].pageSession('end');

            FiltersService.resetFilterValues();

            Analytics.analytics.plugins['insights'].updateTenant(selectedTenant.icaoCode, selectedTenant.label);

            history.push('/');
        } catch (e) {}
    };

    getCustomerById = (customerId) => {
        let { options } = this.state;

        if (options) {
            return options
                .filter((option) => {
                    return option.value === customerId;
                })
                .shift();
        }
    };

    render() {
        let { options, selected, showCustomersDropdown } = this.state;

        if (!showCustomersDropdown) {
            return <div style={{ lineHeight: '40px' }}>{this.props.tenantName}</div>;
        }

        if (options.length === 0) {
            return <div>no options</div>;
        }

        if (selected && selected.label) {
            let selectedLabel = selected.label;
            if (typeof selectedLabel === 'string') {
                selected.label = <div> {selected.label} </div>;
            }
        }

        return (
            <div style={{ width: '270px' }}>
                <Select
                    className="selectBox"
                    style={{ zIndex: '2', marginTop: 5 }}
                    value={selected}
                    name="tenant"
                    options={options}
                    classNamePrefix="select"
                    onChange={this.handleTenantChange}
                    styles={{
                        dropdownIndicator: (provided, state) => ({
                            ...provided,
                            fontSize: '14px'
                        }),
                        menu: (base) => ({
                            ...base,
                            fontSize: '15px',
                            zIndex: '2'
                        }),
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            return {
                                ...styles,
                                backgroundColor: isSelected ? '#ebebeb' : isFocused ? '#f6f6f6' : null,
                                color: '#333333'
                            };
                        }
                    }}
                    components={{ DropdownIndicator }}
                />
            </div>
        );
    }
}

export default withRouter(TenantSwitch);
