import React from 'react';
import { Row } from 'reactstrap';
import HorizontalBarChartContainer from 'components/horizontal-bar-chart/HorizontalBarChartContainer';
import TrendContainer from 'components/trends/TrendContainer';

const page = 'marketplace';
let displayPageName = 'Marketplace';

class MarketplaceOverview extends React.Component {
    state2 = {};
    render() {
        return (
            <div>
                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    allowedApps={['applications']}
                    forceReloadAll={this.props.forceReloadAll}
                    usageSource="MarketplaceSessions"
                    title="Number Of Sessions"
                    workbenchTitle="number of sessions"
                    page={page}
                    pageName={displayPageName}
                    key="marketplace_number_of_sessions_1"
                    id="marketplace_number_of_sessions_1"
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />

                <Row>
                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        contentType="Marketplace"
                        title="Categories"
                        workbenchTitle="categories"
                        page="marketplace"
                        pageName={displayPageName}
                        reportType="categories"
                        color="#F7A77F"
                        key="audio_overview_routes_1"
                        id="audio_overview_routes_1"
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        contentType="Marketplace"
                        title="Products"
                        workbenchTitle="products"
                        page="marketplace"
                        pageName={displayPageName}
                        reportType="products"
                        color="#7FCECD"
                        key="marketplace_categories_1"
                        id="marketplace_categories_1"
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>

                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    allowedApps={this.props.allowedApps}
                    forceReloadAll={this.props.forceReloadAll}
                    usageSource="MarketplaceSessionDuration"
                    title="Session Duration"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="marketplace_session_duration_trend_1"
                    id="marketplace_session_duration_trend_1"
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />

                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    allowedApps={this.props.allowedApps}
                    forceReloadAll={this.props.forceReloadAll}
                    usageSource="MarketplaceVisitorTrends"
                    title="Visitor Trends"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="marketplace_visitor_trend_1"
                    id="marketplace_visitor_trend_1"
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />
            </div>
        );
    }
}

export default MarketplaceOverview;
