import moment from 'moment';
import * as AppConstants from 'constants/app/constants';
import ReleaseNotificationService from 'services/ReleaseNotificationService';
import UtilHelper from 'util/UtilHelper.js';
const utilHelper = new UtilHelper();

class DashboardHelper {
    getSubscriptionStatus = function (startDate, endDate) {
        // Start and end dates are in UTC. Get the current date in UTC.
        let status = AppConstants.SUBSCRIPTION_STATUS_NO_SUBSCRIPTION;

        startDate = utilHelper.formatDateStringWithPattern(startDate, 'YYYYMMDD');
        endDate = utilHelper.formatDateStringWithPattern(endDate, 'YYYYMMDD');

        let expiryDate = moment(endDate).subtract(1, 'days').format('YYYYMMDD');

        if (moment(startDate, 'YYYYMMDD', true).isValid() && moment(expiryDate, 'YYYYMMDD', true).isValid()) {
            let currentDate = moment.utc(new Date()).format('YYYYMMDD');

            if (moment(currentDate).isBetween(startDate, expiryDate, undefined, '[]')) {
                status = AppConstants.SUBSCRIPTION_STATUS_ACTIVE;
            } else if (moment(currentDate).isBefore(startDate)) {
                status = AppConstants.SUBSCRIPTION_STATUS_NOT_STARTED;
            } else if (moment(currentDate).isAfter(expiryDate)) {
                status = AppConstants.SUBSCRIPTION_STATUS_EXPIRED;
            }
        }

        return status;
    };

    getAdminTierAllowedPaths = function () {
        return [
            '/app/help',
            '/app/admin/evaluations',
            '/app/admin/deliveries',
            '/app/admin/data-loading-dashboard',
            '/app/analytics/analytics-dashboard',
            '/app/rack-test-data/rack-offload-dashboard'
        ];
    };

    getPacRackTesterAllowedPaths = function () {
        return ['/app/help', '/app/rack-test-data/rack-offload-dashboard'];
    };

    getInsightsPLMAllowedPaths = function () {
        return ['/app/help', '/app/analytics/analytics-dashboard'];
    };

    getExcludedPaths = function () {
        return [
            '/app/release',
            '/app/help',
            '/app/admin/evaluations',
            '/app/admin/deliveries',
            '/app/admin/data-loading-dashboard',
            '/app/analytics/analytics-dashboard',
            '/app/rack-test-data/rack-offload-dashboard'
        ];
    };

    getPagePath = function (apps, currentPath) {
        let path = null;

        const paths = [
            { key: 'My Insights', path: '/app' },
            { key: 'Movies', path: '/app/entertainment/movies' },
            { key: 'TV Series', path: '/app/entertainment/tv-series' },
            { key: 'Audio', path: '/app/entertainment/audio' },
            { key: 'Games', path: '/app/entertainment/games' },
            { key: 'Live TV', path: '/app/entertainment/live-tv' },
            { key: 'Electronic Reader', path: '/app/entertainment/ereader' },
            { key: 'Seatback', path: '/app/web-portal/seatback' },
            { key: 'Portal', path: '/app/web-portal/portal' },
            { key: 'Portal Navigation', path: '/app/web-portal/portal-navigation' },
            { key: 'Companion App', path: '/app/web-portal/companion-app' },
            { key: 'Bluetooth', path: '/app/web-portal/bluetooth' },
            { key: 'Help', path: '/app/help' },
            { key: 'Marketplace', path: '/app/ecommerce/marketplace' }
        ];

        const adminPaths = [
            { key: 'Evaluations', path: '/app/admin/evaluations' },
            { key: 'Deliveries', path: '/app/admin/deliveries' },
            { key: 'Data Loading Dashboard', path: '/app/admin/data-loading-dashboard' },
            { key: 'NEXT Insights', path: '/app/analytics/analytics-dashboard' },
            { key: 'Rack Offload Dashboard', path: '/app/rack-test-data/rack-offload-dashboard' }
        ];

        if (currentPath) {
            if (
                sessionStorage.getItem('pac_selected_tenant_icao_code') ===
                    AppConstants.TENANT_ICAO_PANASONIC_AVIONICS &&
                (currentPath === '/app/' || currentPath === '/app')
            ) {
                path = '/app/help';
            }

            if (!path && apps && Object.keys(apps).length > 0) {
                for (let i in paths) {
                    if (paths[i].path === currentPath || paths[i].path + '/' === currentPath) {
                        path = paths[i].path;
                        break;
                    }
                }
            }

            if (!path) {
                for (let i in adminPaths) {
                    if (adminPaths[i].path === currentPath || adminPaths[i].path + '/' === currentPath) {
                        path = adminPaths[i].path;
                        break;
                    }
                }
            }
        }

        if (!path && apps && Object.keys(apps).length > 0) {
            for (let i in paths) {
                if (apps.indexOf(paths[i].key) > -1) {
                    path = paths[i].path;
                    break;
                }
            }
        }

        if (!path) {
            path = '/app/help';
        }

        return path;
    };

    getAppName = function (path) {
        let appName = '';

        if (path) {
            const paths = [
                { path: '/app', app: 'My Insights' },
                { path: '/app/', app: 'My Insights' },
                { path: '/app/ecommerce/marketplace', app: 'Marketplace' },
                { path: '/app/entertainment/movies', app: 'Movies' },
                { path: '/app/entertainment/tv-series', app: 'TV Series' },
                { path: '/app/entertainment/audio', app: 'Audio' },
                { path: '/app/entertainment/games', app: 'Games' },
                { path: '/app/entertainment/live-tv', app: 'Live TV' },
                { path: '/app/entertainment/ereader', app: 'Electronic Reader' },
                { path: '/app/web-portal/seatback', app: 'Seatback' },
                { path: '/app/web-portal/portal', app: 'Portal' },
                { path: '/app/web-portal/portal-navigation', app: 'Portal Navigation' },
                { path: '/app/web-portal/companion-app', app: 'Companion App' },
                { path: '/app/web-portal/bluetooth', app: 'Bluetooth' }
            ];

            for (let i in paths) {
                if (paths[i].path === path || paths[i].path + '/' === path) {
                    appName = paths[i].app;
                    break;
                }
            }
        }

        return appName;
    };

    getStubbedSubscription = function (tenantIcaoCode) {
        let response = '';

        if (tenantIcaoCode) {
            if (tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY) {
                response = {
                    tiers: [
                        {
                            'subscription-level': 'Standard',
                            'aircraft-tails': ['*'],
                            'license-start-date': '20220101',
                            'license-end-date': '21001231',
                            stage: 2,
                            'display-banner': true,
                            apps: [
                                'Portal Navigation',
                                'Seatback',
                                'Companion App',
                                'Live TV',
                                'Games',
                                'Audio',
                                'TV Series',
                                'Movies',
                                'Electronic Reader',
                                'Bluetooth',
                                'Marketplace'
                            ],
                            content_types: [
                                'Live TV',
                                'Games',
                                'Audio',
                                'TV Series',
                                'Movies',
                                'Electronic Reader',
                                'Other',
                                'Trailer',
                                'Info Videos',
                                'Video'
                            ]
                        }
                    ],
                    'tenant-icao-code': 'PAC',
                    version: ReleaseNotificationService.softwareReleaseVersion()
                };
            } else if (tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) {
                response = {
                    tiers: [
                        {
                            'subscription-level': 'Basic',
                            'aircraft-tails': ['*'],
                            'license-start-date': '20220101',
                            'license-end-date': '21001231',
                            stage: 2,
                            'display-banner': true,
                            apps: [],
                            content_types: []
                        }
                    ],
                    'tenant-icao-code': 'P4LL',
                    version: ReleaseNotificationService.softwareReleaseVersion()
                };
            } else if (
                window.sessionStorage.getItem('rack_data_env') == 'All' ||
                window.sessionStorage.getItem('rack_data_env') == 'Rack'
            ) {
                response = {
                    tiers: [
                        {
                            'subscription-level': 'Standard',
                            'aircraft-tails': ['*'],
                            'license-start-date': '20220101',
                            'license-end-date': '21001231',
                            stage: 3,
                            'display-banner': true,
                            apps: [
                                'Portal Navigation',
                                'Portal',
                                'Seatback',
                                'Companion App',
                                'Live TV',
                                'Games',
                                'Audio',
                                'TV Series',
                                'Movies',
                                'Electronic Reader',
                                'Marketplace',
                                'My Insights',
                                'Bluetooth'
                            ],
                            content_types: [
                                'Live TV',
                                'Games',
                                'Audio',
                                'TV Series',
                                'Movies',
                                'Electronic Reader',
                                'Other',
                                'Trailer',
                                'Info Videos',
                                'Video'
                            ]
                        },
                        {
                            'subscription-level': 'Basic',
                            'aircraft-tails': ['*'],
                            'license-start-date': '20220101',
                            'license-end-date': '21001231',
                            stage: 3,
                            'display-banner': true,
                            apps: [
                                'Portal Navigation',
                                'Seatback',
                                'Companion App',
                                'Live TV',
                                'Games',
                                'Audio',
                                'TV Series',
                                'Movies',
                                'Electronic Reader',
                                'Marketplace',
                                'My Insights',
                                'Bluetooth'
                            ],
                            content_types: [
                                'Live TV',
                                'Games',
                                'Audio',
                                'TV Series',
                                'Movies',
                                'Electronic Reader',
                                'Other',
                                'Trailer',
                                'Info Videos',
                                'Video'
                            ]
                        }
                    ],
                    'tenant-icao-code': tenantIcaoCode,
                    version: ReleaseNotificationService.softwareReleaseVersion()
                };
            }
        }

        return response;
    };
}

export default DashboardHelper;
