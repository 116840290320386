import React from 'react';
import { ToolMenu, ToolMenuItem, ToolMenuChild, IconHome24, IconReport24 } from 'pac-ux-components';
import menus, { getTitleFromPath } from 'data/menus.js';
import SubscriptionService from 'services/SubscriptionService';
import ReleaseNotificationService from 'services/ReleaseNotificationService';
import Analytics from 'components/widgets/Analytics';
import { I18n } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { Modal, Container, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import chevronImageWhite from 'assets/images/icons/chevronWhite.svg';
import chevronImageBlue from 'assets/images/icons/chevronBlue.svg';
import DashboardHelper from 'components/widgets/dashboards/DashboardHelper';
import * as AppConstants from '../../constants/app/constants';
import FiltersService from 'services/FiltersService';
import PageTabs from 'components/widgets/PageTabs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
var __release_notes_html_basic = require('content/release-notes/release_basic.js');
var __release_notes_html_standard = require('content/release-notes/release_standard.js');

const dashboardHelper = new DashboardHelper();
const releaseNotificationService = new ReleaseNotificationService();

class PageToolsSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentMenuItem: null,
            currentMenuItemChild: null,
            menuItemActive: false,
            isClosed: false,
            pacTenantIcaCode: null,
            tenantId: null,
            tenantName: '',
            tenantIcaoCode: null,

            allSubscriptionTiers: [],
            subscription: '',
            allowedApps: [],
            showPopup: false,
            modal: props.modal,
            activeMenuItems: {},
            activeMenuItemsOriginal: {},
            tierDropdown: false,
            selectedTier: 'Standard',
            userInAdminGroup: false,
            userInPacRackTesterGroup: false,
            userInInsightsPLMGroup: false,
            showFeatures: true,
            newFeaturesTooltip: false,
            isRackDataMode: 'Production'
        };

        this.pageTabsRef = React.createRef();

        this.handleClosed = this.handleClosed.bind(this);
        this.handleSidebar = this.handleSidebar.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.handleOnToggleExpand = this.handleOnToggleExpand.bind(this);
        this.toggleTierDropdown = this.toggleTierDropdown.bind(this);
        this.toggleSelectedTier = this.toggleSelectedTier.bind(this);
        this.toggleNewFeaturesTooltip = this.toggleNewFeaturesTooltip.bind(this);
    }

    componentDidMount() {
        this.handleSidebar();
        window.addEventListener(
            'resize',
            function () {
                this.handleSidebar();
            }.bind(this)
        );
        this.fetchSesssion();
    }

    componentDidUpdate() {
        if (this.state.menuItemActive && this.props.location.pathname !== this.state.menuItemActive) {
            this.setState({ menuItemActive: this.props.location.pathname });
        } else if (window.sessionStorage.getItem('rack_data_env') == 'Rack') {
            this.updateDisclaimer();
        }
    }

    toggleNewFeaturesTooltip(open = false) {
        if (open) {
            if (releaseNotificationService.getViewCount() === 0) {
                this.setState({ newFeaturesTooltip: !this.state.newFeaturesTooltip });
            }
        } else {
            this.setState({ newFeaturesTooltip: !this.state.newFeaturesTooltip });
        }
    }

    updateUserReleaseNotificationViewStatus() {
        if (releaseNotificationService.getViewCount() === 0) {
            releaseNotificationService.updateUserReleaseNotificationViewStatus(
                sessionStorage.getItem('pac_subscription')
            );
        }
    }

    changeTab = (tabIndex, workbenchType, scrollToTop) => {
        if (tabIndex === 1) {
            this.setState({ showFeatures: false });
        } else {
            this.setState({ showFeatures: true });
        }

        if (this.pageTabsRef.current) {
            this.pageTabsRef.current.setActive(tabIndex);
        }
    };

    renderMenuItem(menuItem, menuItemIndex) {
        let { allowedApps, menuItemActive, tenantIcaoCode } = this.state;
        let { history } = this.props;
        let { userInAdminGroup, userInPacRackTesterGroup, userInInsightsPLMGroup } = this.state;

        let iconComponent = menuItem.title === 'Home' ? <IconHome24 /> : <IconReport24 />;

        if (menuItem.icon) {
            iconComponent = menuItem.icon;
        }

        let self = this;
        if (menuItem.children.length > 0) {
            let displayMenu = false;
            for (let i = 0; i < menuItem.children.length; i++) {
                let childItem = menuItem.children[i];
                if (
                    !childItem.verifyApp ||
                    (childItem.verifyApp &&
                        childItem.isAdmin &&
                        userInAdminGroup &&
                        tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                    (childItem.verifyApp &&
                        childItem.isPacRackTester &&
                        userInPacRackTesterGroup &&
                        tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                    (childItem.verifyApp &&
                        childItem.isInsightsPLM &&
                        userInInsightsPLMGroup &&
                        tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                    (childItem.verifyApp && allowedApps.includes(childItem.title))
                ) {
                    displayMenu = true;
                    break;
                }
            }

            if (displayMenu) {
                return (
                    <ToolMenuItem
                        key={menuItemIndex}
                        as="div"
                        href={menuItem.path}
                        title={I18n.get(menuItem.altTitle || menuItem.title)}
                        icon={iconComponent}
                        onClick={(event) => {
                            self.setState({ currentMenuItem: menuItem });
                            return true;
                        }}
                        isActive={menuItemActive && menuItemActive === menuItem.path}
                        isExpanded={menuItem.isExpanded}
                        onToggleExpand={(event) => {
                            event.preventDefault();
                            this.handleOnToggleExpand(menuItem);
                        }}
                    >
                        {menuItem.children.map(function (item, index) {
                            let displayItem = false;
                            if (
                                !item.verifyApp ||
                                (item.verifyApp &&
                                    item.isAdmin &&
                                    userInAdminGroup &&
                                    tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                                (item.verifyApp &&
                                    item.isPacRackTester &&
                                    userInPacRackTesterGroup &&
                                    tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                                (item.verifyApp &&
                                    item.isInsightsPLM &&
                                    userInInsightsPLMGroup &&
                                    tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) ||
                                (item.verifyApp && allowedApps.includes(item.title)) ||
                                (item.verifyApp && item.altTitle && allowedApps.includes(item.altTitle))
                            ) {
                                displayItem = true;
                            }

                            if (displayItem) {
                                return (
                                    <ToolMenuChild
                                        key={index}
                                        as="div"
                                        href={item.path}
                                        title={I18n.get(item.altTitle || item.title)}
                                        onClick={(event) => {
                                            if (self.state.menuItemActive === item.path) {
                                                return false;
                                            }

                                            Analytics.analytics.plugins['insights'].pageSession('end');

                                            self.setState({
                                                currentMenuItemChild: item,
                                                menuItemActive: item.path
                                            });
                                            history.push(item.path);

                                            Analytics.analytics.plugins['insights'].pageSession(
                                                'start',
                                                item.altTitle || item.title
                                            );

                                            return false;
                                        }}
                                        isActive={menuItemActive && menuItemActive === item.path}
                                    />
                                );
                            } else {
                                return '';
                            }
                        })}
                    </ToolMenuItem>
                );
            }
        } else {
            if (!menuItem.verifyApp || (menuItem.verifyApp && allowedApps.includes(menuItem.title))) {
                return (
                    <ToolMenuItem
                        key={menuItemIndex}
                        as="div"
                        href={menuItem.path}
                        title={I18n.get(menuItem.altTitle || menuItem.title)}
                        icon={iconComponent}
                        className="cursor-pointer"
                        onClick={(event) => {
                            if (self.state.menuItemActive === menuItem.path) {
                                return false;
                            }

                            Analytics.analytics.plugins['insights'].pageSession('end');

                            self.setState({ currentMenuItem: menuItem, menuItemActive: menuItem.path });
                            history.push(menuItem.path);

                            Analytics.analytics.plugins['insights'].pageSession(
                                'start',
                                menuItem.altTitle || menuItem.title
                            );

                            return false;
                        }}
                        isActive={menuItemActive && menuItemActive === menuItem.path}
                    />
                );
            }
        }
    }

    getReleaseNotesHtml() {
        return this.state.subscription &&
            this.state.subscription === 'Standard' &&
            this.state.selectedTier === 'Standard'
            ? __release_notes_html_standard
            : __release_notes_html_basic;
    }

    getFeaturesHtml() {
        return this.state.subscription &&
            this.state.subscription === 'Standard' &&
            this.state.selectedTier === 'Standard'
            ? '/features/features_standard.html'
            : '/features/features_basic.html';
    }

    handleOnToggleExpand(menuItem) {
        let { activeMenuItems } = this.state;

        activeMenuItems = activeMenuItems.map(function (item, index) {
            if (item.path === menuItem.path) {
                item.isExpanded = !item.isExpanded;
            }

            return item;
        });

        this.setState({
            activeMenuItems: activeMenuItems
        });
    }

    handleClosed() {
        let { isClosed } = this.state;

        this.setState({
            isClosed: !isClosed,
            newFeaturesTooltip: false
        });

        return !isClosed;
    }

    handleSidebar() {
        if (window.innerWidth < 1400) {
            this.setState({
                isClosed: true,
                newFeaturesTooltip: false
            });
        } else {
            this.setState({
                isClosed: false,
                newFeaturesTooltip: false
            });
        }
    }

    togglePopup() {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            showPopup: !this.state.showPopup
        }));
    }

    updateDisclaimer() {
        let { tenantIcaoCode, allSubscriptionTiers, selectedTier } = this.state;
        let popupHeader = '';
        let popupMessage = '';

        if (document.getElementById('app-disclaimer')) {
            document.getElementById('app-disclaimer').style.display = 'none';
        }

        if (
            tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY ||
            tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS
        ) {
            if (document.getElementById('app-disclaimer')) {
                document.getElementById('app-disclaimer').style.display = 'initial';
                document.getElementById('app-disclaimer').className = 'app-disclaimer default';
            }

            if (tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY) {
                document.getElementById('subscription_stage_msg').innerText = AppConstants.DISCLAIMER_PANASKY_HEADER;
                popupMessage = AppConstants.DISCLAIMER_PANASKY;
                popupHeader = AppConstants.DISCLAIMER_PANASONIC_AVIONICS_HEADER;
            }

            if (tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) {
                document.getElementById('subscription_stage_msg').innerText =
                    AppConstants.DISCLAIMER_PANASONIC_AVIONICS_HEADER + AppConstants.DISCLAIMER_PANASONIC_AVIONICS;
                popupMessage = AppConstants.DISCLAIMER_PANASONIC_AVIONICS;
                popupHeader = AppConstants.DISCLAIMER_PANASONIC_AVIONICS_HEADER;
            }
        } else if (sessionStorage.getItem('rack_data_env') && sessionStorage.getItem('rack_data_env') === 'Rack') {
            if (this.state.isRackDataMode !== sessionStorage.getItem('rack_data_env')) {
                var disclaimerElement = document.getElementById('app-disclaimer');
                var wrapper = document.createElement('div');
                var span = document.createElement('span');
                span.innerText = AppConstants.RACK_DATA_MODE_HEADER;

                var link = document.createElement('a');
                link.href = AppConstants.RACK_DATA_MODE_USER_GUIDE;
                link.innerText = 'User Guide';
                link.target = '_blank';
                var paragraphToRemove = disclaimerElement.querySelector('p');
                disclaimerElement.removeChild(paragraphToRemove);

                wrapper.appendChild(span);
                wrapper.appendChild(link);

                disclaimerElement.appendChild(wrapper);

                this.setState({ isRackDataMode: sessionStorage.getItem('rack_data_env') });
            }
            document.getElementById('app-disclaimer').style.display = 'flex';
            document.getElementById('app-disclaimer').style.justifyContent = 'center';
            document.getElementById('app-disclaimer').style.alignItems = 'center';
        } else {
            let subscriptionStage = AppConstants.SUBSCRIPTION_STAGE_0;
            let subscriptionStatus = AppConstants.SUBSCRIPTION_STATUS_NO_SUBSCRIPTION;

            if (allSubscriptionTiers.length === 1) {
                subscriptionStage = allSubscriptionTiers[0]['stage'];
                subscriptionStatus = dashboardHelper.getSubscriptionStatus(
                    allSubscriptionTiers[0]['license-start-date'],
                    allSubscriptionTiers[0]['license-end-date']
                );
            } else {
                for (let i = 0; i < allSubscriptionTiers.length; i++) {
                    if (allSubscriptionTiers[i]['subscription-level'] === selectedTier) {
                        subscriptionStage = allSubscriptionTiers[i]['stage'];
                        subscriptionStatus = dashboardHelper.getSubscriptionStatus(
                            allSubscriptionTiers[i]['license-start-date'],
                            allSubscriptionTiers[i]['license-end-date']
                        );
                        break;
                    }
                }
            }

            if (AppConstants.SUBSCRIPTION_STAGES.includes(subscriptionStage)) {
                if (document.getElementById('app-disclaimer')) {
                    document.getElementById('app-disclaimer').style.display = 'initial';
                }

                let disclaimerText = '';

                if (subscriptionStatus === AppConstants.SUBSCRIPTION_STATUS_ACTIVE) {
                    if (subscriptionStage === AppConstants.SUBSCRIPTION_STAGE_1) {
                        disclaimerText =
                            AppConstants.DISCLAIMER_STAGE_1_ACTIVE_HEADER + AppConstants.DISCLAIMER_STAGE_1_ACTIVE;
                        popupMessage = AppConstants.DISCLAIMER_STAGE_1_ACTIVE;
                        popupHeader = AppConstants.DISCLAIMER_STAGE_1_ACTIVE_HEADER;
                        document.getElementById('app-disclaimer').className = 'app-disclaimer default';
                    } else if (subscriptionStage === AppConstants.SUBSCRIPTION_STAGE_2) {
                        disclaimerText =
                            AppConstants.DISCLAIMER_STAGE_2_ACTIVE_HEADER + AppConstants.DISCLAIMER_STAGE_2_ACTIVE;
                        popupMessage = AppConstants.DISCLAIMER_STAGE_2_ACTIVE;
                        popupHeader = AppConstants.DISCLAIMER_STAGE_2_ACTIVE_HEADER;
                        document.getElementById('app-disclaimer').className = 'app-disclaimer default';
                    } else {
                        if (document.getElementById('app-disclaimer')) {
                            document.getElementById('app-disclaimer').style.display = 'none';
                        }
                    }
                } else {
                    if (subscriptionStatus === AppConstants.SUBSCRIPTION_STATUS_EXPIRED) {
                        disclaimerText = AppConstants.DISCLAIMER_STAGE_2_EXPIRED;
                        document.getElementById('app-disclaimer').className = 'app-disclaimer expired';
                    } else if (subscriptionStatus === AppConstants.SUBSCRIPTION_STATUS_NOT_STARTED) {
                        disclaimerText = AppConstants.DISCLAIMER_STAGE_2_FUTURE;
                        document.getElementById('app-disclaimer').className = 'app-disclaimer future';
                    }
                }
                document.getElementById('subscription_stage_msg').innerText = disclaimerText;
            }
        }

        if (document.getElementById('app-disclaimer')) {
            if (document.getElementById('app-disclaimer').style.display === 'none') {
                if (document.getElementsByClassName('top-header')[0]) {
                    document.getElementsByClassName('top-header')[0].style.paddingTop = '15px';
                    document.getElementsByClassName('top-header')[0].style.height = '72px';
                }
                if (document.getElementById('content-container')) {
                    document.getElementById('content-container').style.transition = 'all .25s ease-in-out';
                    //document.getElementById('content-container').style.transition = "margin 0.25s linear";
                    document.getElementById('content-container').style.marginTop = '25px';
                }
                let sideBar = document.getElementsByClassName('side-bar')[0];
                if (sideBar) {
                    sideBar.style.transition = 'all .25s ease-in-out';
                    //sideBar.style.transition = "margin 0.25s linear";
                    sideBar.style.marginTop = '0';
                }
            } else {
                if (document.getElementsByClassName('top-header')[0]) {
                    document.getElementsByClassName('top-header')[0].style.paddingTop = '55px';
                    document.getElementsByClassName('top-header')[0].style.height = '112px';
                }
                if (document.getElementById('content-container')) {
                    document.getElementById('content-container').style.transition = 'all .25s ease-in-out';
                    //document.getElementById('content-container').style.transition = "margin 0.25s linear";
                    document.getElementById('content-container').style.marginTop = '65px';
                }
                let sideBar = document.getElementsByClassName('side-bar')[0];
                if (sideBar) {
                    sideBar.style.transition = 'all .25s ease-in-out';
                    //sideBar.style.transition = "margin 0.25s linear";
                    sideBar.style.marginTop = '40px';
                }
            }
        }

        //show popup
        if (popupMessage !== '' && popupHeader !== '') {
            this.props.showDisclaimerPopupAction(popupHeader, popupMessage);
        }
    }

    toggleTierDropdown() {
        if (
            (this.state.subscription === 'Standard' &&
                this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY) ||
            this.state.allSubscriptionTiers.length > 1
        ) {
            this.setState((prevState) => ({
                tierDropdown: !prevState.tierDropdown
            }));
        }
    }

    toggleSelectedTier(selectedTier) {
        try {
            // Subscription tier selection change
            if (
                (this.state.subscription === 'Standard' &&
                    this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY) ||
                this.state.allSubscriptionTiers.length > 1
            ) {
                let allowedApps = [];
                let subscriptionStatus = AppConstants.SUBSCRIPTION_STATUS_NO_SUBSCRIPTION;

                let menuItemActive;

                if (selectedTier === 'Basic') {
                    let activeMenuItems = menus['sidebar'];

                    for (let i = 0; i < this.state.allSubscriptionTiers.length; i++) {
                        if (this.state.allSubscriptionTiers[i]['subscription-level'] === 'Basic') {
                            allowedApps = JSON.parse(JSON.stringify(this.state.allSubscriptionTiers[i]['apps']));
                            subscriptionStatus = dashboardHelper.getSubscriptionStatus(
                                this.state.allSubscriptionTiers[i]['license-start-date'],
                                this.state.allSubscriptionTiers[i]['license-end-date']
                            );
                        }
                    }

                    menuItemActive = this.state.menuItemActive;

                    if (
                        subscriptionStatus !== AppConstants.SUBSCRIPTION_STATUS_ACTIVE &&
                        this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASKY
                    ) {
                        activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true); // && menuItem.isExpanded === true);
                        menuItemActive = '/app/help';
                        allowedApps = [];
                    }

                    this.setState(
                        {
                            selectedTier: selectedTier,
                            allowedApps: allowedApps,
                            activeMenuItems: activeMenuItems,
                            menuItemActive: menuItemActive
                        },
                        () => {
                            this.reRenderMenuItem();
                        }
                    );
                } else {
                    let activeMenuItems = this.state.activeMenuItemsOriginal;

                    for (let i = 0; i < this.state.allSubscriptionTiers.length; i++) {
                        if (this.state.allSubscriptionTiers[i]['subscription-level'] === 'Standard') {
                            allowedApps = JSON.parse(JSON.stringify(this.state.allSubscriptionTiers[i]['apps']));
                            subscriptionStatus = dashboardHelper.getSubscriptionStatus(
                                this.state.allSubscriptionTiers[i]['license-start-date'],
                                this.state.allSubscriptionTiers[i]['license-end-date']
                            );
                        }
                    }

                    menuItemActive = this.state.menuItemActive;
                    if (subscriptionStatus !== AppConstants.SUBSCRIPTION_STATUS_ACTIVE || allowedApps.length === 0) {
                        activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true); // && menuItem.isExpanded === true);
                        menuItemActive = '/app/help'; // TODO: keep an eye
                        allowedApps = [];
                    }

                    this.setState(
                        {
                            selectedTier: selectedTier,
                            allowedApps: allowedApps,
                            activeMenuItems: activeMenuItems,
                            menuItemActive: menuItemActive // TODO: keep an eye
                        },
                        () => {
                            this.reRenderMenuItem();
                        }
                    );
                }

                Analytics.analytics.plugins['insights'].pageSession('end');
                Analytics.analytics.track('toggleSelectedTier', {
                    visual_name: 'Tiers',
                    interaction_type: 'Tier Switch',
                    interaction_value: selectedTier
                });
                Analytics.analytics.plugins['insights'].updateTier(selectedTier);
                Analytics.analytics.plugins['insights'].pageSession('start', getTitleFromPath(menuItemActive));

                let landingPagePath = dashboardHelper.getPagePath(this.state.allowedApps, this.state.menuItemActive);
                this.props.onUpdatedTier(selectedTier, landingPagePath);
                window.sessionStorage.setItem('pac_subscription', selectedTier);
                window.sessionStorage.removeItem('pac_next_insights_selected_filters');
                FiltersService.resetFilterValues();
            }
        } catch (e) {
            console.error(e);
        }
    }

    reRenderMenuItem() {
        if (this.state.activeMenuItems) {
            this.state.activeMenuItems.map((menuItem, menuItemIndex) => {
                return this.renderMenuItem(menuItem, menuItemIndex);
            });
        }
    }

    renderTierDropdown() {
        let tierDropdownOptions = [];

        for (let i = 0; i < this.state.allSubscriptionTiers.length; i++) {
            if (this.state.allSubscriptionTiers[i]['subscription-level'] === 'Standard') {
                tierDropdownOptions.push(
                    <div
                        className="tier-info-standard"
                        onClick={() => this.toggleSelectedTier('Standard')}
                        key={'subscription_dropdown_standard'}
                    >
                        <div className="dropdown-standard">
                            <div style={{ float: 'left' }}>Standard</div>
                            {this.state.selectedTier === 'Standard' ? (
                                <i className="fas fa-check icon-check-mark">&nbsp;</i>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="dropdown-desc">Enhanced analytics feature set.</div>
                    </div>
                );
            } else if (this.state.allSubscriptionTiers[i]['subscription-level'] === 'Basic') {
                tierDropdownOptions.push(
                    <div
                        className="tier-info-basic"
                        onClick={() => this.toggleSelectedTier('Basic')}
                        key={'subscription_dropdown_basic'}
                    >
                        <div className="dropdown-basic">
                            <div style={{ float: 'left' }}>Basic</div>
                            {this.state.selectedTier === 'Basic' ? (
                                <i className="fas fa-check icon-check-mark">&nbsp;</i>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="dropdown-desc">Limited analytics feature set.</div>
                    </div>
                );
            }
        }

        return <React.Fragment>{tierDropdownOptions}</React.Fragment>;
    }

    getSubscriptionClass(tierName) {
        let subscriptionClass = 'subscription-dropdown';
        if (this.state.subscription === 'Standard') {
            if (this.state.selectedTier === 'Standard') {
                if (
                    this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY ||
                    this.state.allSubscriptionTiers.length > 1
                ) {
                    subscriptionClass = 'subscription-dropdown standard';
                } else {
                    subscriptionClass = 'subscription-dropdown standard non-pac';
                }
            } else {
                subscriptionClass = 'subscription-dropdown basic';
            }
        } else if (this.state.subscription === 'Basic') {
            if (tierName === 'Internal') {
                subscriptionClass = 'subscription-dropdown internal';
            } else {
                subscriptionClass = 'subscription-dropdown';
            }
        } else {
            subscriptionClass = 'subscription-dropdown no-subscription';
        }

        return subscriptionClass;
    }

    onReleaseSelected = (props) => {
        Analytics.analytics.track('onReleaseSelected', {
            visual_name: 'Release Notes',
            interaction_type: 'Link Click',
            interaction_value: 'Link Click'
        });

        this.setState({
            showPopup: !this.state.showPopup,
            modal: !this.state.modal,
            showFeatures: true,
            newFeaturesTooltip: false
        });
    };

    render() {
        let { isClosed } = this.state;

        let activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true);

        const closeBtn = (
            <button className="close" onClick={this.togglePopup}>
                &times;
            </button>
        );

        const createMarkup = () => {
            return { __html: this.getReleaseNotesHtml() };
        };

        let tierName = '';
        let tierCursor = 'default';
        let multipleTiers = false;
        if (this.state.subscription) {
            tierName = this.state.subscription;
            if (
                (this.state.subscription === 'Standard' &&
                    this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY) ||
                this.state.allSubscriptionTiers.length > 1
            ) {
                tierName = this.state.selectedTier;
                tierCursor = 'pointer';
                multipleTiers = true;
            } else if (this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) {
                tierName = 'Internal';
            }
        }

        let subscriptionClass = this.getSubscriptionClass(tierName);
        let tierDropdownList = this.renderTierDropdown();

        return (
            <div className={this.state.isClosed ? 'side-bar collapsed' : 'side-bar'}>
                <ToolMenu isClosed={isClosed} handleClosed={this.handleClosed}>
                    {
                        <OverlayTrigger
                            placement="right"
                            show={this.state.newFeaturesTooltip}
                            overlay={
                                <Tooltip className="chart-tooltip">
                                    <p style={{ margin: '10px 14px 10px 14px' }}>
                                        New Features can be viewed here
                                        <span
                                            style={{ cursor: 'pointer', paddingLeft: '15px' }}
                                            onClick={this.toggleNewFeaturesTooltip}
                                        >
                                            X
                                        </span>
                                    </p>
                                </Tooltip>
                            }
                        >
                            <div className="side-bar-version">
                                <div
                                    className={subscriptionClass}
                                    title={''}
                                    onClick={this.toggleTierDropdown}
                                    style={{ cursor: tierCursor }}
                                >
                                    <div
                                        className="rectangle"
                                        style={{ visibility: this.state.subscription ? 'visible' : 'hidden' }}
                                    >
                                        <div className={tierName.toLowerCase()}>{tierName}</div>
                                        {multipleTiers ? (
                                            <img
                                                className={this.state.tierDropdown ? 'chevron' : 'chevron down'}
                                                alt=""
                                                src={
                                                    this.state.selectedTier === 'Standard'
                                                        ? chevronImageWhite
                                                        : chevronImageBlue
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>

                                    <div className={this.state.tierDropdown ? 'dropdown' : 'dropdown closed'}>
                                        {tierDropdownList}
                                    </div>
                                </div>

                                <div
                                    className="version-link"
                                    title="Release Notes"
                                    onClick={(event) => {
                                        this.onReleaseSelected(this.props);
                                    }}
                                >
                                    v{ReleaseNotificationService.releaseVersion()}
                                </div>
                            </div>
                        </OverlayTrigger>
                    }

                    {this.state.showPopup ? (
                        <Container>
                            <Modal isOpen={this.state.modal} toggle={this.togglePopup} className="release-notes">
                                <ModalHeader toggle={this.togglePopup} close={closeBtn}>
                                    {' '}
                                </ModalHeader>
                                <ModalBody>
                                    <PageTabs
                                        tabs={['Release Notes', "What's New"]}
                                        tabFunction={this.changeTab}
                                        ref={this.pageTabsRef}
                                        path={this.props.match.path}
                                    />
                                    <div
                                        className="row release-notes-wrapper"
                                        style={{
                                            paddingTop: '15px',
                                            display: this.state.showFeatures ? 'block' : 'none'
                                        }}
                                    >
                                        <div className="main-title">
                                            <h1>Release Notes</h1>
                                        </div>
                                        <div className="content">
                                            <div dangerouslySetInnerHTML={createMarkup()}></div>
                                        </div>
                                    </div>
                                    <div
                                        className="row features-wrapper"
                                        style={{
                                            paddingTop: '15px',
                                            display: this.state.showFeatures ? 'none' : 'block'
                                        }}
                                    >
                                        <div className="main-title">
                                            <h1>Features in v{ReleaseNotificationService.releaseVersion()}</h1>
                                        </div>
                                        <div className="content" style={{ overflow: 'hidden', paddingRight: '0px' }}>
                                            <iframe
                                                title="features-tab"
                                                src={this.getFeaturesHtml()}
                                                width="100%"
                                                height="100%"
                                                frameBorder="0"
                                            />
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <p>
                                        <small>
                                            Copyright ©{new Date().getFullYear()} Panasonic Avionics Corporation. All
                                            rights reserved.
                                        </small>
                                    </p>
                                </ModalFooter>
                            </Modal>
                        </Container>
                    ) : null}

                    {activeMenuItems.map((menuItem, menuItemIndex) => {
                        return this.renderMenuItem(menuItem, menuItemIndex);
                    })}
                </ToolMenu>
            </div>
        );
    }

    fetchSesssion() {
        let currTenantIcaoCode = sessionStorage.getItem('pac_selected_tenant_icao_code');

        if (!currTenantIcaoCode) {
            setTimeout(
                function () {
                    this.fetchSesssion();
                }.bind(this),
                1000
            );
            return;
        }

        Auth.currentSession({})
            .then(async (session) => {
                let payload = session.idToken.payload;
                let tenantId = payload.pac_tenant_id;
                let tenantName = payload.pac_tenant_name;
                let currTenantId = sessionStorage.getItem('pac_selected_tenant_id');
                let currentTenantId = currTenantId ? currTenantId : tenantId;

                let pacIcaoCode = payload.pac_icao_code;

                let currentTenantIcaoCode = currTenantIcaoCode ? currTenantIcaoCode : pacIcaoCode;

                const groups = payload['cognito:groups'];
                let userInAdminGroup = false;
                let userInPacRackTesterGroup = false;
                let userInInsightsPLMGroup = false;
                if (typeof groups !== 'undefined') {
                    for (let i = 0; i < groups.length; i++) {
                        if (AppConstants.ADMIN_GROUPS.includes(groups[i])) {
                            userInAdminGroup = true;
                            //break;
                        }

                        if (groups[i] === AppConstants.GROUP_PAC_RACK_TESTER) {
                            userInPacRackTesterGroup = true;
                        }

                        if (groups[i] === AppConstants.GROUP_INSIGHTS_PLM) {
                            userInInsightsPLMGroup = true;
                        }
                    }
                }

                this.setState({
                    userInAdminGroup: userInAdminGroup,
                    userInPacRackTesterGroup: userInPacRackTesterGroup,
                    userInInsightsPLMGroup: userInInsightsPLMGroup,
                    tenantName: tenantName,
                    tenantIcaoCode: currentTenantIcaoCode,
                    pacTenantIcaCode: payload.pac_icao_code
                });

                await this.fetchSubscription(currentTenantId);

                try {
                    let viewStatus = await releaseNotificationService.fetchUserReleaseNotificationViewStatus(
                        sessionStorage.getItem('pac_subscription')
                    );

                    if (
                        this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASKY &&
                        this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASONIC_AVIONICS
                    ) {
                        if (!(await releaseNotificationService.popupDisplayed())) {
                            if (viewStatus['data']['release_notification_status']) {
                                this.props.showReleaseNotificationAction('', '');
                            }
                        }
                    } else {
                        this.updateDisclaimer();
                    }
                } catch (e) {
                    console.log(e);
                }
            })
            .catch((err) => console.warn(err));
    }

    async fetchSubscription(tenantId) {
        await SubscriptionService.fetchSubscription()
            .then((response) => {
                if (!response && !response.tiers) {
                    //
                } else {
                    if (
                        this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS ||
                        sessionStorage.getItem('rack_data_env') == 'All' ||
                        sessionStorage.getItem('rack_data_env') == 'Rack'
                    ) {
                        response = dashboardHelper.getStubbedSubscription(this.state.tenantIcaoCode);
                    }

                    if (Array.isArray(response.tiers) && Object.keys(response.tiers).length > 0) {
                        let defaultTier = response.tiers[0];
                        for (let k in response.tiers) {
                            if (
                                response.tiers[k].apps.includes('eReader') &&
                                !response.tiers[k].apps.includes('Electronic Reader')
                            ) {
                                response.tiers[k].apps.push('Electronic Reader');
                            }

                            if (response.tiers[k]['subscription-level'] === 'Standard') {
                                defaultTier = response.tiers[k];
                                break;
                            }
                        }

                        window.sessionStorage.setItem('pac_subscription', defaultTier['subscription-level']);
                        this.setState({
                            subscription: defaultTier['subscription-level'],
                            allSubscriptionTiers: response.tiers
                        });

                        if (defaultTier.apps && Array.isArray(defaultTier.apps) && defaultTier.apps.length > 0) {
                            let subscriptionStatus = dashboardHelper.getSubscriptionStatus(
                                defaultTier['license-start-date'],
                                defaultTier['license-end-date']
                            );

                            if (subscriptionStatus === AppConstants.SUBSCRIPTION_STATUS_ACTIVE) {
                                let activePagePath;
                                let subscriptionLevel = defaultTier['subscription-level'];

                                if (
                                    defaultTier.apps.length === 1 &&
                                    defaultTier.apps[0] === 'Electronic Reader' &&
                                    this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASONIC_AVIONICS
                                ) {
                                    let activeMenuItems = menus['sidebar'].filter(
                                        (menuItem) => menuItem.isActive === true
                                    );
                                    activeMenuItems = activeMenuItems.filter((menuItem) => menuItem.path !== '/app');
                                    activePagePath = '/app/entertainment/ereader';
                                    this.setState({ activeMenuItems: activeMenuItems, menuItemActive: activePagePath });
                                } else {
                                    if (subscriptionLevel === 'Basic') {
                                        let activeMenuItems = menus['sidebar'].filter(
                                            (menuItem) => menuItem.isActive === true
                                        );
                                        activePagePath = dashboardHelper.getPagePath(
                                            defaultTier.apps,
                                            this.props.location.pathname
                                        );
                                        this.setState({
                                            activeMenuItems: activeMenuItems,
                                            menuItemActive: activePagePath
                                        });
                                    } else {
                                        let activeMenuItems = menus['sidebar'].filter(
                                            (menuItem) => menuItem.isActive === true
                                        );
                                        activePagePath = this.props.location.pathname;
                                        this.setState({
                                            activeMenuItems: activeMenuItems,
                                            menuItemActive: activePagePath,
                                            activeMenuItemsOriginal: activeMenuItems
                                        });
                                    }
                                }

                                Analytics.analytics.plugins['insights'].updateTier(subscriptionLevel);
                                Analytics.analytics.plugins['insights'].pageSession(
                                    'start',
                                    getTitleFromPath(activePagePath)
                                );

                                this.setState({
                                    allowedApps: defaultTier.apps
                                });
                            } else {
                                let activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true); // && menuItem.isExpanded === true);

                                Analytics.analytics.plugins['insights'].updateTier(defaultTier['subscription-level']);
                                Analytics.analytics.plugins['insights'].pageSession(
                                    'start',
                                    getTitleFromPath('/app/help')
                                );

                                this.setState({ activeMenuItems: activeMenuItems, menuItemActive: '/app/help' });
                                this.setState({
                                    allowedApps: []
                                });
                            }
                        } else {
                            let activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true); // && menuItem.isExpanded === true);

                            Analytics.analytics.plugins['insights'].updateTier(defaultTier['subscription-level']);
                            Analytics.analytics.plugins['insights'].pageSession(
                                'start',
                                getTitleFromPath(dashboardHelper.getPagePath([], this.props.location.pathname))
                            );

                            this.setState({
                                activeMenuItems: activeMenuItems,
                                menuItemActive: dashboardHelper.getPagePath([], this.props.location.pathname)
                            });
                            this.setState({
                                allowedApps: []
                            });
                        }
                    } else {
                        let activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true); // && menuItem.isExpanded === true);

                        Analytics.analytics.plugins['insights'].pageSession(
                            'start',
                            getTitleFromPath(dashboardHelper.getPagePath([], this.props.location.pathname))
                        );

                        this.setState({
                            activeMenuItems: activeMenuItems,
                            menuItemActive: dashboardHelper.getPagePath([], this.props.location.pathname)
                        });
                        this.setState({
                            subscription: 'No Subscription',
                            allowedApps: []
                        });
                    }
                }
            })
            .catch((error) => {
                console.log('PageToolsSidebar- fetchSubscriptionData - ERROR');
            });
    }
}

export default PageToolsSidebar;
