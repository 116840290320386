import React from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import FiltersService from 'services/FiltersService';
const TENANT_OPTIONS_SESSION_KEY = 'pac_tenants';
const SELECTED_TENANT_ID_SESSION_KEY = 'pac_selected_tenant_id';
const SELECTED_TENANT_ICAO_CODE_SESSION_KEY = 'pac_selected_tenant_icao_code';
const SELECTED_TENANT_SESSION_KEY = 'pac_selected_tenant';
const NONLICENSED_MODE = 'rack_data_env';
const NONLICENSED_TENANTS = 'nonlicensed_tenants';

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faCaretUp : faCaretDown} />
            </components.DropdownIndicator>
        )
    );
};

class RackDataDropdown extends React.Component {
    constructor(props) {
        super(props);

        let options = [];

        if (this.props.isRackUser) {
            options = [
                { label: 'Production Data', value: 'Production' },
                { label: 'Production Data - All', value: 'All' },
                { label: 'Rack Data', value: 'Rack' }
            ];

            this.state = {
                options: options,
                selected: this.props.rackDataEnv ? options[2] : this.props.allDataEnv ? options[1] : options[0]
            };
        } else {
            options = [
                { label: 'Production Data', value: 'Production' },
                { label: 'Production Data - All', value: 'All' }
            ];

            this.state = {
                options: options,
                selected: this.props.allDataEnv ? options[1] : options[0]
            };
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (option) => {
        if (option.value !== this.state.selected.value) {
            window.sessionStorage.setItem('rack_data_env', option.value);
            window.sessionStorage.removeItem('pac_next_insights_selected_filters');

            let options = JSON.parse(window.sessionStorage.getItem(TENANT_OPTIONS_SESSION_KEY));
            if (
                window.sessionStorage.getItem(NONLICENSED_MODE) == 'All' ||
                window.sessionStorage.getItem(NONLICENSED_MODE) == 'Rack'
            ) {
                options = JSON.parse(window.sessionStorage.getItem(NONLICENSED_TENANTS));
            }
            let selectedTenant = JSON.parse(window.sessionStorage.getItem(SELECTED_TENANT_SESSION_KEY));
            selectedTenant = options
                .filter((option) => {
                    return option.icaoCode.toString() === selectedTenant.icaoCode.toString();
                })
                .shift();
            if (!selectedTenant) {
                if (
                    window.sessionStorage.getItem(NONLICENSED_MODE) == 'All' ||
                    window.sessionStorage.getItem(NONLICENSED_MODE) == 'Rack'
                ) {
                    selectedTenant = options[0];
                } else {
                    selectedTenant = {
                        label: 'Panasky',
                        value: '903a42bc-985e-40d1-9c72-32b8c2e33397',
                        icaoCode: 'pac'
                    };
                }
            }

            window.sessionStorage.setItem(SELECTED_TENANT_ID_SESSION_KEY, selectedTenant.value);
            window.sessionStorage.setItem(SELECTED_TENANT_SESSION_KEY, JSON.stringify(selectedTenant));
            window.sessionStorage.setItem(SELECTED_TENANT_ICAO_CODE_SESSION_KEY, selectedTenant.icaoCode);

            FiltersService.resetFilterValues();
            let { history } = this.props;
            this.setState({
                selected: option
            });
            history.push('/');
        }
    };

    render() {
        let { selected } = this.state;
        if (selected && selected.label) {
            let selectedLabel = selected.label;
            if (typeof selectedLabel === 'string') {
                selected.label = <div> {selected.label} </div>;
            }
        }

        return (
            <div style={{ width: '270px' }}>
                <Select
                    className="selectBox"
                    style={{ zIndex: '2', marginTop: 5 }}
                    value={this.state.selected}
                    name="tenant"
                    options={this.state.options}
                    classNamePrefix="select"
                    onChange={this.handleChange}
                    isSearchable={false}
                    styles={{
                        dropdownIndicator: (provided, state) => ({
                            ...provided,
                            fontSize: '14px'
                        }),
                        menu: (base) => ({
                            ...base,
                            fontSize: '15px',
                            zIndex: '2'
                        }),
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            return {
                                ...styles,
                                backgroundColor: isSelected ? '#ebebeb' : isFocused ? '#f6f6f6' : null,
                                color: '#333333'
                            };
                        }
                    }}
                    components={{ DropdownIndicator }}
                />
            </div>
        );
    }
}

export default RackDataDropdown;
