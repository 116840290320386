import React from 'react';
import { CardBody, Row, Col, Card } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import ChartsDashBoard from 'components/widgets/dashboards/ChartsDashBoard.js';
import SubscriptionService from 'services/SubscriptionService';
import { Auth } from 'aws-amplify';
import TenantSwitch from 'components/widgets/dashboards/TenantSwitch';
import QuicksightWorkbenchComponent from 'components/workbench/QuicksightWorkbenchComponent';
import menus, { getTitleFromPath } from 'data/menus.js';
import * as AppConstants from 'constants/app/constants';
import Circular from 'assets/images/icons/circular.gif';
import DashboardHelper from 'components/widgets/dashboards/DashboardHelper';
import AuthHelper from 'util/AuthHelper.js';
import PageHeader from 'components/containers/PageHeader.js';
import RackDataDropdown from 'components/widgets/dashboards/RackDataDropdown';

const authHelper = new AuthHelper();
const dashboardHelper = new DashboardHelper();

const paidTiers = ['Standard'];
const basicTier = 'Basic';

class DashboardBuilder extends React.Component {
    constructor(props) {
        super(props);

        this.handleSelectionUpdated = this.handleSelectionUpdated.bind(this);
        this.handleTenantSelection = this.handleTenantSelection.bind(this);

        this.state = {
            tenantId: null,
            originalTenantId: null,
            tenantName: '',
            dataset: null,
            dashboard: null,
            show: true,
            selectedValue: null,
            allSubscriptionTiers: [],
            subscription: '',
            subscriptionStage: '',
            allowedApps: [],
            allowedContentTypes: [],
            licenseStartDate: '',
            licenseEndDate: '',
            subscriptionSet: false,
            originalTenantIcaoCode: '',
            tenantIcaoCode: '',
            showSummary: true,
            appliedFilters: {},
            onlyComparisonRangeUpdated: false,
            reloadAirlineSummary: false,
            reloadDynamicFilters: false,
            reloadTrendInQueue: false,
            reloadTrend: false,
            reloadAll: false,
            timestamp: '',
            isAdminTier: false,
            userInAdminGroup: false,
            userInPacRackTesterGroup: false,
            userInInsightsPLMGroup: false,
            userPACInternal: false,
            showRackDataDropdown: false,
            rackDataEnv: false
        };

        this.selectRef = React.createRef();
        this.quicksightWorkbenchRef = React.createRef();
        this.dynamicFilterRef = React.createRef();
    }

    handleTenantSelection(selectedIcao) {
        this.setState({
            tenantIcaoCode: selectedIcao
        });
    }

    async fetchSesssion() {
        return new Promise((resolve, reject) => {
            Auth.currentSession({})
                .then(async (session) => {
                    let payload = session.idToken.payload;
                    let tenantId = payload.pac_tenant_id;
                    let tenantName = payload.pac_tenant_name;
                    let pacIcaoCode = payload.pac_icao_code;

                    let cognitoGroups = payload['cognito:groups'];
                    let cognitoAssignedSubject = payload.sub;
                    let currTenantId = sessionStorage.getItem('pac_selected_tenant_id');
                    if (currTenantId && currTenantId !== tenantId) {
                        let currTenantIcaoCode = sessionStorage.getItem('pac_selected_tenant_icao_code');
                        if (currTenantIcaoCode) {
                            pacIcaoCode = currTenantIcaoCode;
                        }
                    }
                    let currentTenantId = currTenantId ? currTenantId : tenantId;

                    const groups = payload['cognito:groups'];
                    let userInAdminGroup = false;
                    let userInPacRackTesterGroup = false;
                    let userInInsightsPLMGroup = false;
                    let userPACInternal = false;
                    if (typeof groups !== 'undefined') {
                        for (let i = 0; i < groups.length; i++) {
                            if (AppConstants.ADMIN_GROUPS.includes(groups[i])) {
                                userInAdminGroup = true;
                            }

                            if (groups[i] === AppConstants.GROUP_PAC_RACK_TESTER) {
                                userInPacRackTesterGroup = true;
                            }

                            if (groups[i] === AppConstants.GROUP_INSIGHTS_PLM) {
                                userInInsightsPLMGroup = true;
                            }
                        }
                    }

                    if (tenantId == AppConstants.PAC_TENANT_ID) {
                        userPACInternal = true;
                    }

                    if (pacIcaoCode && pacIcaoCode.toLowerCase() === AppConstants.TENANT_ICAO_PANASKY) {
                        if (authHelper.currentUserBelongsToOnlyPacRackTesters(session)) {
                            pacIcaoCode = AppConstants.TENANT_ICAO_PANASONIC_AVIONICS;
                        }
                    }

                    let showRackDataDropdown = false;
                    if (
                        pacIcaoCode &&
                        userInPacRackTesterGroup &&
                        pacIcaoCode != AppConstants.TENANT_ICAO_PANASONIC_AVIONICS
                    ) {
                        showRackDataDropdown = true;
                    } else if (
                        pacIcaoCode &&
                        pacIcaoCode != AppConstants.TENANT_ICAO_PANASONIC_AVIONICS &&
                        userPACInternal
                    ) {
                        showRackDataDropdown = true;
                    }

                    let rackDataEnv = window.sessionStorage.getItem('rack_data_env') == 'Rack';

                    let subscriptionData = await this.fetchSubscription(
                        currentTenantId,
                        pacIcaoCode,
                        cognitoAssignedSubject,
                        cognitoGroups
                    );

                    if (this.dynamicFilterRef.current) {
                        this.dynamicFilterRef.current.getSelectedFiltersFromStore();
                    }

                    resolve({
                        tenantId: currentTenantId,
                        tenantName: tenantName,
                        tenantIcaoCode: pacIcaoCode,
                        userInAdminGroup: userInAdminGroup,
                        userInPacRackTesterGroup: userInPacRackTesterGroup,
                        userInInsightsPLMGroup: userInInsightsPLMGroup,
                        originalTenantId: payload.pac_tenant_id,
                        originalTenantIcaoCode: payload.pac_icao_code,
                        ...subscriptionData,
                        showRackDataDropdown: showRackDataDropdown,
                        rackDataEnv: rackDataEnv,
                        userPACInternal: userPACInternal
                    });
                })
                .catch(async (err) => {
                    console.warn(err);
                    await Auth.signOut();
                    this.props.history.push('/');
                });
        });
    }

    async fetchSubscription(tenantId, pacIcaoCode, cognitoAssignedSubject, cognitoGroups) {
        try {
            let response = null;
            if (
                pacIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS ||
                sessionStorage.getItem('rack_data_env') == 'Rack' ||
                sessionStorage.getItem('rack_data_env') == 'All'
            ) {
                response = dashboardHelper.getStubbedSubscription(pacIcaoCode);
            } else {
                response = await SubscriptionService.fetchSubscription();
            }

            if (!response && !response.tiers) {
                return {};
            } else {
                let tempSubscription = '';
                let tempStage = '';
                let tempAllowedApps = [];
                let tempAllowedContentTypes = [];
                let licenseStartDate = '';
                let licenseEndDate = '';
                let isAdminTier = false;

                if (Array.isArray(response.tiers) && Object.keys(response.tiers).length > 0) {
                    let defaultTier = response.tiers[0];
                    for (let k in response.tiers) {
                        if (response.tiers[k]['subscription-level'] === 'Standard') {
                            defaultTier = response.tiers[k];
                            break;
                        }
                    }
                    tempSubscription = defaultTier['subscription-level'];
                    tempStage = defaultTier['stage'];

                    if (defaultTier.apps && Array.isArray(defaultTier.apps) && defaultTier.apps.length > 0) {
                        tempAllowedApps = defaultTier.apps;
                    }

                    if (
                        defaultTier.content_types &&
                        Array.isArray(defaultTier.content_types) &&
                        defaultTier.content_types.length > 0
                    ) {
                        tempAllowedContentTypes = defaultTier.content_types;
                    }

                    if (defaultTier['license-start-date']) {
                        licenseStartDate = defaultTier['license-start-date'];
                    }

                    if (defaultTier['license-end-date']) {
                        licenseEndDate = defaultTier['license-end-date'];
                    }
                } else {
                    tempSubscription = 'No Subscription';
                }

                if (pacIcaoCode === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) {
                    isAdminTier = true;
                }

                if (tempSubscription === basicTier) {
                    if (this.props.match.path === '/app' || this.props.match.path === '/app/') {
                        let basicLandingPagePath = dashboardHelper.getPagePath(tempAllowedApps);
                        this.props.history.push(basicLandingPagePath);
                    }
                }

                SubscriptionService.setStage(tempStage);

                return {
                    subscription: tempSubscription,
                    subscriptionStage: tempStage,
                    allowedApps: tempAllowedApps,
                    allowedContentTypes: tempAllowedContentTypes,
                    licenseStartDate: licenseStartDate,
                    licenseEndDate: licenseEndDate,
                    isAdminTier: isAdminTier,
                    allSubscriptionTiers: response.tiers,
                    subscriptionSet: true
                };
            }
        } catch (error) {
            console.log('DashboardBuilder - fetchSubscriptionData - ERROR');
            return {};
        }
    }

    async componentDidMount() {
        let sessionData = await this.fetchSesssion();
        this.setState({ ...sessionData });
    }

    async componentDidUpdate(prevProps, prevState) {
        let updatedStateValues = null;

        // Subscription tier selection change
        let updatedSubscription = sessionStorage.getItem('pac_subscription');
        if (['Basic', 'Standard'].includes(updatedSubscription)) {
            if (this.state.subscription !== updatedSubscription) {
                let subscriptionStage = '';
                let allowedApps = [];
                let allowedContentTypes = [];
                let licenseStartDate = '';
                let licenseEndDate = '';

                for (let i = 0; i < this.state.allSubscriptionTiers.length; i++) {
                    if (this.state.allSubscriptionTiers[i]['subscription-level'] === updatedSubscription) {
                        allowedApps = JSON.parse(JSON.stringify(this.state.allSubscriptionTiers[i]['apps']));
                        allowedContentTypes = JSON.parse(
                            JSON.stringify(this.state.allSubscriptionTiers[i]['content_types'])
                        );
                        licenseStartDate = JSON.parse(
                            JSON.stringify(this.state.allSubscriptionTiers[i]['license-start-date'])
                        );
                        licenseEndDate = JSON.parse(
                            JSON.stringify(this.state.allSubscriptionTiers[i]['license-end-date'])
                        );
                        subscriptionStage = JSON.parse(JSON.stringify(this.state.allSubscriptionTiers[i]['stage']));
                    }
                }

                SubscriptionService.setStage(subscriptionStage);

                updatedStateValues = {
                    subscription: updatedSubscription,
                    subscriptionStage: subscriptionStage,
                    allowedApps: allowedApps,
                    allowedContentTypes: allowedContentTypes,
                    licenseStartDate: licenseStartDate,
                    licenseEndDate: licenseEndDate,
                    reloadDynamicFilters: true,
                    reloadAirlineSummary: true,
                    reloadAll: true
                };
            }
        }

        if (this.props.match.path !== prevProps.match.path) {
            let sessionData = await this.fetchSesssion();
            if (!updatedStateValues) {
                updatedStateValues = {};
            }
            this.setState({ reloadAirlineSummary: true, showSummary: true, ...updatedStateValues, ...sessionData });
        } else if (updatedStateValues) {
            this.setState({ ...updatedStateValues });
        }
    }

    getMainTitle() {
        console.debug(this.props.match);

        if (this.props) {
            let path = this.props.match.path;
            if (this.props.match.path === '/app/') {
                path = '/app';
            }
            return getTitleFromPath(path);
        }

        return '';
    }

    handleDisclaimer() {
        if (document.getElementById('app-disclaimer')) {
            if (document.getElementById('app-disclaimer').style.display === 'none') {
                if (document.getElementsByClassName('top-header')[0]) {
                    document.getElementsByClassName('top-header')[0].style.paddingTop = '15px';
                    document.getElementsByClassName('top-header')[0].style.height = '72px';
                }
                if (document.getElementById('content-container')) {
                    document.getElementById('content-container').style.marginTop = '25px';
                }
            } else {
                if (document.getElementsByClassName('top-header')[0]) {
                    document.getElementsByClassName('top-header')[0].style.paddingTop = '55px';
                    document.getElementsByClassName('top-header')[0].style.height = '112px';
                }
                if (document.getElementById('content-container')) {
                    document.getElementById('content-container').style.marginTop = '65px';
                }
            }
        }
    }

    displayWorkbenchByPath() {
        if (this.props) {
            if (dashboardHelper.getExcludedPaths().includes(this.props.match.path)) {
                return false;
            }
        }

        // TODO: remove after bluetooth quicksight dashboard is developed.
        if (
            this.props.match.path === '/app/web-portal/bluetooth' &&
            this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASONIC_AVIONICS
        ) {
            return false;
        }

        if (this.props.match.path === '/app/ecommerce/marketplace') {
            return false;
        }

        return true;
    }

    userHasAccess() {
        let { tenantIcaoCode, allowedApps, isAdminTier, userInPacRackTesterGroup, userInInsightsPLMGroup } = this.state;
        let userHasAccess = AppConstants.USER_HAS_ACCESS_CHECKING;

        if (
            this.props.match.path === '/app' ||
            this.props.match.path === '/app/' ||
            this.props.match.path === '/app/help' ||
            this.props.match.path === '/app/help/'
        ) {
            return AppConstants.USER_HAS_ACCESS_YES;
        }

        if (tenantIcaoCode && allowedApps && allowedApps.length > 0) {
            let activeMenuItems = menus['sidebar'].filter((menuItem) => menuItem.isActive === true);

            loop1: for (let i = 0; i < activeMenuItems.length; i++) {
                let menuItem = activeMenuItems[i];
                if (menuItem.path === this.props.match.path || menuItem.path + '/' === this.props.match.path) {
                    if (
                        !menuItem.verifyApp ||
                        (menuItem.verifyApp && menuItem.isAdmin && isAdminTier) ||
                        (menuItem.verifyApp && menuItem.isPacRackTester && userInPacRackTesterGroup && isAdminTier) ||
                        (menuItem.verifyApp && menuItem.isInsightsPLM && userInInsightsPLMGroup && isAdminTier) ||
                        (menuItem.verifyApp && allowedApps.includes(menuItem.title))
                    ) {
                        userHasAccess = AppConstants.USER_HAS_ACCESS_YES;
                    } else {
                        userHasAccess = AppConstants.USER_HAS_ACCESS_NO;
                    }
                    break;
                } else {
                    if (menuItem.children.length > 0) {
                        for (let i = 0; i < menuItem.children.length; i++) {
                            let childItem = menuItem.children[i];

                            if (
                                childItem.path === this.props.match.path ||
                                childItem.path + '/' === this.props.match.path
                            ) {
                                if (
                                    !childItem.verifyApp ||
                                    (childItem.verifyApp && childItem.isAdmin && isAdminTier) ||
                                    (childItem.verifyApp &&
                                        childItem.isPacRackTester &&
                                        userInPacRackTesterGroup &&
                                        isAdminTier) ||
                                    (childItem.verifyApp &&
                                        childItem.isInsightsPLM &&
                                        userInInsightsPLMGroup &&
                                        isAdminTier) ||
                                    (childItem.verifyApp && allowedApps.includes(childItem.title))
                                ) {
                                    userHasAccess = AppConstants.USER_HAS_ACCESS_YES;
                                } else {
                                    userHasAccess = AppConstants.USER_HAS_ACCESS_NO;
                                }
                                break loop1;
                            }
                        }
                    }
                }
            }
        } else {
            if (this.state.isAdminTier) {
                if (dashboardHelper.getAdminTierAllowedPaths().includes(this.props.match.path)) {
                    if (dashboardHelper.getPacRackTesterAllowedPaths().includes(this.props.match.path)) {
                        if (this.state.userInPacRackTesterGroup) {
                            return AppConstants.USER_HAS_ACCESS_YES;
                        } else {
                            return AppConstants.USER_HAS_ACCESS_NO;
                        }
                    } else if (dashboardHelper.getInsightsPLMAllowedPaths().includes(this.props.match.path)) {
                        if (this.state.userInInsightsPLMGroup) {
                            return AppConstants.USER_HAS_ACCESS_YES;
                        } else {
                            return AppConstants.USER_HAS_ACCESS_NO;
                        }
                    } else {
                        if (this.state.userInAdminGroup) {
                            return AppConstants.USER_HAS_ACCESS_YES;
                        } else {
                            return AppConstants.USER_HAS_ACCESS_NO;
                        }
                    }
                } else {
                    return AppConstants.USER_HAS_ACCESS_NO;
                }
            } else {
                if (this.subscriptionNotFound()) {
                    return AppConstants.USER_HAS_NO_SUBSCRIPTION;
                } else {
                    return AppConstants.USER_HAS_ACCESS_CHECKING;
                }
            }
        }

        return userHasAccess;
    }

    isPaidTier() {
        let paidTier = false;
        if (typeof this.state.subscription !== 'undefined') {
            if (paidTiers.indexOf(this.state.subscription) !== -1) {
                paidTier = true;
            }
        }

        return paidTier;
    }

    isBasicTier() {
        let isBasicTier = false;
        if (typeof this.state.subscription !== 'undefined') {
            if (this.state.subscription === basicTier) {
                isBasicTier = true;
            }
        }
        return isBasicTier;
    }

    isStage1() {
        let isStage1 = true;
        if (typeof this.state.subscriptionStage !== 'undefined' && typeof this.state.tenantIcaoCode !== 'undefined') {
            if (
                (this.state.subscriptionStage > 1 && this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASKY) ||
                this.state.tenantIcaoCode === AppConstants.TENANT_ICAO_PANASKY
            ) {
                isStage1 = false;
            }
        }

        return isStage1;
    }

    subscriptionNotFound() {
        let subscriptionNotFound = false;
        if (typeof this.state.subscription !== 'undefined') {
            if (this.state.subscription === 'No Subscription') {
                subscriptionNotFound = true;
            }
        }
        return subscriptionNotFound;
    }

    isForceReloadAirlineSummary() {
        if (this.state.reloadAirlineSummary) {
            this.setState({ reloadAirlineSummary: false });
            return true;
        }

        return false;
    }

    isForceReloadAll() {
        if (this.state.reloadAll) {
            this.setState({ reloadAll: false });
            return true;
        }

        return false;
    }

    getDashboardComponent() {
        let { dataset, tenantId, subscription, subscriptionStage, allowedApps } = this.state;
        let tenantIcaoCode = window.sessionStorage.getItem('pac_selected_tenant_icao_code');

        if (this.state.error) {
            return (
                <div>
                    <Card>
                        <CardBody>
                            <h3>Data Unavailable</h3>
                        </CardBody>
                    </Card>
                </div>
            );
        } else {
            let showWorkbench = this.isPaidTier();
            let forceReloadAll = this.isForceReloadAll();
            const maintitle = this.getMainTitle();
            const appName = dashboardHelper.getAppName(this.props.match.path);
            return (
                <div className="components-wrapper" style={{ display: this.state.showSummary ? 'block' : 'none' }}>
                    <ChartsDashBoard
                        tenantId={tenantId}
                        tenantIcaoCode={tenantIcaoCode}
                        app={appName}
                        subscriptionStage={subscriptionStage}
                        dataset1={dataset}
                        showWorkbench={showWorkbench}
                        tabFunction={this.changeTab}
                        updateQuicksightFiltersFunction={this.updateQuicksightFilters}
                        appliedFilters={this.state.appliedFilters}
                        forceReloadAll={forceReloadAll}
                        allowedApps={allowedApps}
                        onlyComparisonRangeUpdated={this.state.onlyComparisonRangeUpdated}
                        title={maintitle}
                        {...this.props}
                    />
                </div>
            );
        }
    }

    getQuicksightWorkbenchDashboardComponent() {
        let { tenantId, tenantIcaoCode, appliedFilters, allowedContentTypes } = this.state;

        if (this.state.error) {
            return (
                <div className="workbench-wrapper" style={{ display: this.state.showSummary ? 'none' : 'block' }}>
                    Data Unavailable
                </div>
            );
        } else {
            const appName = dashboardHelper.getAppName(this.props.match.path);
            return (
                <div className="workbench-wrapper" style={{ display: this.state.showSummary ? 'none' : 'block' }}>
                    <QuicksightWorkbenchComponent
                        ref={this.quicksightWorkbenchRef}
                        forceLoad={true}
                        tenantId={tenantId}
                        tenantIcaoCode={tenantIcaoCode}
                        app={appName}
                        path={this.props.match.path}
                        tabFunction={this.changeTab}
                        filters={appliedFilters && appliedFilters.quicksight ? appliedFilters.quicksight : ''}
                        allowedContentTypes={allowedContentTypes}
                        loadDataOnMount={false}
                        dateRangeText={appliedFilters && appliedFilters.api ? appliedFilters.api.dateRangeText : ''}
                        {...this.props}
                    />
                </div>
            );
        }
    }

    updateQuicksightFilters = (filters) => {
        // todo : add another parameter and set it from dashboarcbuilder.componentdidupate-IF
        if (filters) {
            if (this.quicksightWorkbenchRef.current) {
                this.quicksightWorkbenchRef.current.updateFilters(filters);
            } else {
                setTimeout(
                    function () {
                        if (this.quicksightWorkbenchRef.current) {
                            this.quicksightWorkbenchRef.current.updateFilters(filters);
                        }
                    }.bind(this),
                    3000
                );
            }
        }
    };

    changeTab = (tabIndex, workbenchType, scrollToTop) => {
        if (tabIndex === 1) {
            this.setState({ showSummary: false });
            this.quicksightWorkbenchRef.current.changeSelected(workbenchType);
        } else {
            this.setState({ showSummary: true });
            if (this.state.reloadTrendInQueue) {
                this.setState({ reloadTrend: true });
            }
        }
    };

    handleSelectionUpdated(appliedFilters, onlyComparisonRangeUpdated) {
        if (typeof this.state.subscription !== 'undefined') {
            if (!this.isPaidTier()) {
                if (appliedFilters.api && appliedFilters.api.comparison_range) {
                    delete appliedFilters.api.comparison_range;
                    delete appliedFilters.api.comparison_range_text;
                }
            }
        }

        if (!this.state.showSummary) {
            this.setState({ reloadTrendInQueue: true });
        }

        this.setState({ appliedFilters: appliedFilters, onlyComparisonRangeUpdated: onlyComparisonRangeUpdated }, () =>
            this.refreshReports(appliedFilters)
        );
    }

    refreshReports(appliedFilters) {
        if (appliedFilters && appliedFilters.quicksight) {
            this.updateQuicksightFilters(appliedFilters.quicksight);
        }
    }

    render() {
        const maintitle = this.getMainTitle();
        const appName = dashboardHelper.getAppName(this.props.match.path);

        let isProductionAllEnv = sessionStorage.getItem('rack_data_env') == 'All';

        let userHasAccess = AppConstants.USER_HAS_ACCESS_CHECKING;
        let pacSubscription = sessionStorage.getItem('pac_subscription');

        if (
            this.state.tenantIcaoCode &&
            this.state.subscription &&
            (!pacSubscription || (pacSubscription && pacSubscription === this.state.subscription)) &&
            this.state.subscriptionSet &&
            this.state.subscription.length > 0
        ) {
            userHasAccess = this.userHasAccess();
        }

        if (userHasAccess === AppConstants.USER_HAS_ACCESS_YES) {
            if (
                this.subscriptionNotFound() ||
                (this.state.allowedApps.length === 0 &&
                    this.state.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASONIC_AVIONICS)
            ) {
                if (this.props.match.path !== '/app/help' && this.props.match.path !== '/app/help/') {
                    let landingPagePath = dashboardHelper.getPagePath(this.state.allowedApps);
                    return <Redirect to={{ pathname: landingPagePath }} />;
                }
            }

            let subscriptionStatus = dashboardHelper.getSubscriptionStatus(
                this.state.licenseStartDate,
                this.state.licenseEndDate
            );

            if (this.state.userInAdminGroup && this.state.userInInsightsPLMGroup && isProductionAllEnv) {
                subscriptionStatus = AppConstants.SUBSCRIPTION_STATUS_ACTIVE;
            }
            if (subscriptionStatus !== AppConstants.SUBSCRIPTION_STATUS_ACTIVE) {
                if (this.props.match.path !== '/app/help' && this.props.match.path !== '/app/help/') {
                    let landingPagePath = dashboardHelper.getPagePath([]);
                    return <Redirect to={{ pathname: landingPagePath }} />;
                }
            }

            let refreshAirlineSummary = this.isForceReloadAirlineSummary();
            let dashboard = this.getDashboardComponent(refreshAirlineSummary);
            let quicksightWorkbenchDashboard = '';

            let showWorkbench = false;
            let paidTier = this.isPaidTier();
            let isStage1 = this.isStage1();
            if (paidTier) {
                if (this.displayWorkbenchByPath() && !isStage1) {
                    showWorkbench = true;
                }
            }

            if (showWorkbench) {
                quicksightWorkbenchDashboard = this.getQuicksightWorkbenchDashboardComponent();
            }

            let currTenantIcaoCode = sessionStorage.getItem('pac_selected_tenant_icao_code');

            return (
                <div>
                    <Row className="top-header" data-loaded={currTenantIcaoCode ? 'yes' : 'no'}>
                        <Col md="6" sm="12" style={{ paddingRight: '0', marginLeft: '10px' }}>
                            <div className="d-flex">
                                <div className="d-inline ml-2">
                                    {this.state.showRackDataDropdown && (
                                        <RackDataDropdown
                                            rackDataEnv={this.state.rackDataEnv}
                                            allDataEnv={isProductionAllEnv}
                                            isRackUser={this.state.userPACInternal}
                                            {...this.props}
                                        />
                                    )}
                                </div>
                                <div className="d-inline ml-2">
                                    {
                                        <TenantSwitch
                                            tenantName={this.state.tenantName}
                                            tenantIcao={this.state.tenantIcaoCode}
                                            originalTenantId={this.state.originalTenantId}
                                            originalTenantIcaoCode={this.state.originalTenantIcaoCode}
                                            onTenantSelection={this.handleTenantSelection}
                                        />
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div
                        id="content-container"
                        style={{ marginTop: '25px', display: currTenantIcaoCode ? 'block' : 'none' }}
                    >
                        <PageHeader
                            subscriptionStatus={subscriptionStatus}
                            path={this.props.match.path}
                            maintitle={maintitle}
                            paidTier={paidTier}
                            appName={appName}
                            refreshAirlineSummary={refreshAirlineSummary}
                            showWorkbench={showWorkbench}
                            licenseStartDate={this.state.licenseStartDate}
                            licenseEndDate={this.state.licenseEndDate}
                            handleSelectionUpdated={this.handleSelectionUpdated}
                            appliedFilters={this.state.appliedFilters}
                            onlyComparisonRangeUpdated={this.state.onlyComparisonRangeUpdated}
                            allowedApps={this.state.allowedApps}
                            changeTab={this.changeTab}
                        />
                        {dashboard}

                        {showWorkbench && quicksightWorkbenchDashboard}
                    </div>

                    <div
                        className={'data-wrapper custom-loading'}
                        style={{ display: currTenantIcaoCode ? 'none' : 'block' }}
                    >
                        <img src={Circular} alt="" />
                    </div>
                    {this.handleDisclaimer()}
                </div>
            );
        } else if (userHasAccess === AppConstants.USER_HAS_ACCESS_CHECKING) {
            return (
                <div className={'data-wrapper custom-loading'}>
                    <img src={Circular} alt="" />
                </div>
            );
        } else {
            return <Redirect to={{ pathname: '/' }} />;
        }
    }
}

export default DashboardBuilder;
