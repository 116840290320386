import React from 'react';

const aclRules = {
    standard: {
        games: {
            metrics: {
                avg_engagement_duration: true,
                total_engagement_time: true,
                total_views: true,
                total_time: true
            },
            sections: {
                top_10_total_views: true,
                top_10_total_time: true,
                bottom_10_total_views: true,
                bottom_10_total_time: true
            }
        },
        'live-tv': {
            metrics: {
                avg_engagement_duration: true,
                total_engagement_time: true,
                total_views: true,
                total_time: true,
                flights: true,
                tails: true,
                destination_countries: true,
                destination_airports: true,
                routes: true,
                total_channel_hits: true,
                total_viewing_time: true,
                total_flight_duration: true,
                total_unique_passengers: true,
                avg_passengers_per_flight: true,
                avg_time_per_flight: true,
                avg_time_per_passenger: true,
                avg_content_viewing_time: true
            },
            sections: {
                top_10_program_titles_total_views: true,
                top_10_program_titles_total_time: true,
                bottom_10_program_titles_total_views: true,
                bottom_10_program_titles_total_time: true,
                top_10_total_views: true,
                top_10_total_time: true,
                top_10_avg_time_per_flight: true,
                top_10_avg_time_per_passenger: true,
                top_10_avg_time_per_view: true,
                top_10_total_flights: true,
                top_10_unique_passengers: true,
                bottom_10_total_views: true,
                bottom_10_total_time: true,
                bottom_10_avg_time_per_flight: true,
                bottom_10_avg_time_per_passenger: true,
                bottom_10_avg_time_per_view: true,
                bottom_10_total_flights: true,
                bottom_10_unique_passengers: true
            }
        },
        movies: {
            metrics: {
                avg_completion_rate: true,
                total_engagement_time: true,
                total_views: true,
                media_duration: true,
                total_time: true
            },
            sections: {
                top_10_total_views: true,
                top_10_total_time: true,
                bottom_10_total_views: true,
                bottom_10_total_time: true
            }
        },
        'tv-series': {
            metrics: {
                avg_completion_rate: true,
                total_engagement_time: true,
                total_views: true,
                media_duration: true,
                total_time: true
            },
            sections: {
                top_10_total_views: true,
                top_10_total_time: true,
                bottom_10_total_views: true,
                bottom_10_total_time: true
            }
        },
        audio: {
            metrics: {
                avg_completion_rate: true,
                total_engagement_time: true,
                total_views: true,
                media_duration: true,
                total_time: true
            },
            sections: {
                top_10_total_views: true,
                top_10_total_time: true,
                bottom_10_total_views: true,
                bottom_10_total_time: true
            }
        },
        ereader: {
            metrics: {
                avg_completion_rate: true,
                time_to_read: true,
                avg_page_reads: true,
                avg_reading_time: true,
                total_readers: true,
                total_reading_time: true,
                total_page_reads: true,
                total_title_views: true,
                unique_title_views: true,
                avg_title_views: true,
                flights: true,
                tails: true,
                destination_countries: true,
                destination_airports: true,
                routes: true
            },
            sections: {
                top_10_total_title_views: true,
                top_10_total_readers: true,
                top_10_total_time: true,
                top_10_avg_time: true,
                bottom_10_total_title_views: true,
                bottom_10_total_readers: true,
                bottom_10_total_time: true,
                bottom_10_avg_time: true
            }
        },
        seatback: {
            metrics: {},
            sections: {
                seat_usage: true,
                passenger_interaction_timeline: true,
                top_10_total_views: true,
                top_10_total_viewers: true,
                top_10_total_time: true,
                top_10_total_transitions: true,
                bottom_10_total_views: true,
                bottom_10_total_viewers: true,
                bottom_10_total_time: true,
                bottom_10_total_transitions: true
            }
        },
        portal: {
            metrics: {},
            sections: {
                passenger_interaction_timeline: true,
                top_10_total_views: true,
                top_10_total_viewers: true,
                top_10_total_time: true,
                bottom_10_total_views: true,
                bottom_10_total_viewers: true,
                bottom_10_total_time: true
            }
        },
        'companion-app': {
            metrics: {},
            sections: {
                passenger_interaction_timeline: true,
                bottom_10_media_launches: true,
                top_10_media_launches: true
            }
        },
        'my-insights': {
            metrics: {},
            sections: {
                passenger_engagement: true,
                total_views: true,
                total_viewing_time: true,
                top_5_total_views: true,
                bottom_5_total_views: true,
                top_5_total_viewing_time: true,
                bottom_5_total_viewing_time: true
            }
        },
        marketplace: {
            metrics: {},
            sections: {
                top_10_total_views: true,
                top_10_total_time: true,
                bottom_10_total_views: true,
                bottom_10_total_time: true
            }
        }
    },
    basic: {
        games: {
            metrics: {
                total_engagement_time: true,
                total_views: true
            },
            sections: {
                top_10_total_views: true,
                bottom_10_total_views: true
            }
        },
        'live-tv': {
            metrics: {
                total_engagement_time: true,
                total_views: true,
                flights: true,
                tails: true,
                destination_countries: true,
                destination_airports: true,
                routes: true,
                total_channel_hits: true,
                total_viewing_time: true,
                total_flight_duration: true,
                total_unique_passengers: true
            },
            sections: {
                top_10_program_titles_total_views: true,
                bottom_10_program_titles_total_views: true,
                top_10_total_views: true,
                top_10_total_flights: true,
                top_10_unique_passengers: true,
                bottom_10_total_views: true,
                bottom_10_total_flights: true,
                bottom_10_unique_passengers: true
            }
        },
        movies: {
            metrics: {
                total_engagement_time: true,
                total_views: true
            },
            sections: {
                top_10_total_views: true,
                bottom_10_total_views: true
            }
        },
        'tv-series': {
            metrics: {
                total_engagement_time: true,
                total_views: true
            },
            sections: {
                top_10_total_views: true,
                bottom_10_total_views: true
            }
        },
        audio: {
            metrics: {
                total_engagement_time: true,
                total_views: true
            },
            sections: {
                top_10_total_views: true,
                bottom_10_total_views: true
            }
        },
        ereader: {
            metrics: {
                total_page_reads: true,
                time_to_read: true,
                total_readers: true,
                total_reading_time: true,
                total_title_views: true,
                unique_title_views: true,
                avg_title_views: true,
                flights: true,
                tails: true,
                destination_countries: true,
                destination_airports: true,
                routes: true
            },
            sections: {
                top_10_total_title_views: true,
                bottom_10_total_title_views: true
            }
        },
        seatback: {
            metrics: {},
            sections: {
                top_10_total_views: true,
                bottom_10_total_views: true,
                top_10_total_transitions: true,
                bottom_10_total_transitions: true,
                top_10_total_viewers: true,
                bottom_10_total_viewers: true
            }
        },
        portal: {
            metrics: {},
            sections: {
                top_10_total_views: true,
                bottom_10_total_views: true
            }
        },
        'companion-app': {
            metrics: {},
            sections: {
                top_10_media_launches: true,
                bottom_10_media_launches: true
            }
        },
        'my-insights': {
            metrics: {},
            sections: {
                total_views: true,
                top_5_total_views: true,
                bottom_5_total_views: true
            }
        },
        marketplace: {
            metrics: {},
            sections: {
                top_10_total_views: true,
                top_10_total_time: true,
                bottom_10_total_views: true,
                bottom_10_total_time: true
            }
        }
    }
};

const getCurrentTier = function () {
    let currentTier = sessionStorage.getItem('pac_subscription');

    if (currentTier === null) {
        currentTier = 'basic';
    } else {
        currentTier = currentTier.toLowerCase();
    }

    return currentTier;
};

class AccessControl extends React.Component {
    displayMetric(page, metric, currentTier) {
        if (aclRules[currentTier][page] !== undefined) {
            if (aclRules[currentTier][page]['metrics'][metric] !== undefined) {
                return aclRules[currentTier][page]['metrics'][metric];
            }
        }

        return false;
    }

    displaySection(page, section, currentTier) {
        if (aclRules[currentTier][page] !== undefined) {
            if (aclRules[currentTier][page]['sections'][section] !== undefined) {
                return aclRules[currentTier][page]['sections'][section];
            }
        }

        return false;
    }

    filterMetricsByTier(page, metrics) {
        let currentTier = getCurrentTier();

        return metrics.filter((metric) => {
            if (aclRules[currentTier][page] !== undefined) {
                if (
                    aclRules[currentTier][page]['metrics'][metric] !== undefined &&
                    aclRules[currentTier][page]['metrics'][metric] === true
                ) {
                    return true;
                }
            }
            return false;
        });
    }

    filterSectionsByTier(page, sections) {
        let currentTier = getCurrentTier();

        return sections.filter((section) => {
            if (aclRules[currentTier][page] !== undefined) {
                if (
                    aclRules[currentTier][page]['sections'][section] !== undefined &&
                    aclRules[currentTier][page]['sections'][section] === true
                ) {
                    return true;
                }
            }
            return false;
        });
    }

    render() {
        let currentTier = getCurrentTier();

        if (this.props.metrics !== undefined) {
            for (let i = 0; i < this.props.metrics.length; i++) {
                if (this.displayMetric(this.props.page, this.props.metrics[i], currentTier)) {
                    return this.props.children;
                }
            }
        }

        if (this.props.sections !== undefined) {
            for (let i = 0; i < this.props.sections.length; i++) {
                if (this.displaySection(this.props.page, this.props.sections[i], currentTier)) {
                    return this.props.children;
                }
            }
        }

        // no access default
        return '';
    }
}

export const filterMetricsByTier = AccessControl.prototype.filterMetricsByTier;
export const filterSectionsByTier = AccessControl.prototype.filterSectionsByTier;
export default AccessControl;
