import LanguagesConfig from 'components/horizontal-bar-chart/LanguagesConfig.js';
import GenresConfig from 'components/horizontal-bar-chart/GenresConfig.js';
import CategoriesConfig from 'components/horizontal-bar-chart/CategoriesConfig.js';
import RoutesConfig from 'components/horizontal-bar-chart/RoutesConfig.js';
import PagesConfig from 'components/horizontal-bar-chart/PagesConfig.js';
import ProgramTitlesConfig from 'components/horizontal-bar-chart/ProgramTitlesConfig.js';
import ProductsConfig from 'components/horizontal-bar-chart/ProductsConfig.js';

export default class HorizontalBarChartConfig {
    getAPIPath = function () {
        return '/v2/metrics/popularity_metrics_data';
    };

    getBarChartRequestedSections = function (reportType, reportSubType) {
        let requestedSections;
        switch (reportType) {
            case 'titles':
                requestedSections = ['titles'];
                break;
            case 'languages':
                switch (reportSubType) {
                    case 'Movies':
                    case 'TV Series':
                        requestedSections = ['soundtrack_languages', 'subtitle_languages'];
                        break;
                    case 'Electronic Reader':
                    case 'EReader':
                        requestedSections = ['ereader_languages'];
                        break;
                    case 'Seatback':
                        requestedSections = ['lang_trans', 'selected_lang'];
                        break;
                    default:
                        requestedSections = ['soundtrack_languages'];
                }
                break;
            case 'genres':
                requestedSections = ['genres'];
                break;
            case 'categories':
                requestedSections = ['categories'];
                break;
            case 'routes':
                requestedSections = ['routes'];
                break;
            case 'pages':
                requestedSections = ['pages'];
                break;
            case 'portal_browsing_pages':
                requestedSections = ['pages'];
                break;
            case 'events':
                switch (reportSubType) {
                    case 'Companion App':
                        requestedSections = ['companion_app_events'];
                        break;
                    default:
                        break;
                }
                break;
            case 'program-titles':
                requestedSections = ['program_titles'];
                break;
            case 'products':
                requestedSections = ['products'];
                break;
            default:
                break;
        }

        return requestedSections;
    };

    getDropdownList = function (requestedSection, reportSubType) {
        let dropdownList;

        switch (requestedSection) {
            case 'languages':
                dropdownList = LanguagesConfig.getLanguagesDropdownListHelper(reportSubType);
                break;
            case 'genres':
                dropdownList = GenresConfig.getGenresDropdownListHelper(reportSubType);
                break;
            case 'categories':
                dropdownList = CategoriesConfig.getCategoriesDropdownListHelper(reportSubType);
                break;
            case 'routes':
                dropdownList = RoutesConfig.getRoutesDropdownListHelper(reportSubType);
                break;
            case 'pages':
                dropdownList = PagesConfig.getPagesDropdownListHelper(reportSubType);
                break;
            case 'program-titles':
                dropdownList = ProgramTitlesConfig.getProgramTitlesDropdownListHelper(reportSubType);
                break;
            case 'products':
                dropdownList = ProductsConfig.getProductsDropdownListHelper(reportSubType);
                break;
            default:
                break;
        }

        return dropdownList;
    };
}
